import React, { ReactNode } from 'react'
import { Field, FormikErrors, FormikTouched } from 'formik'
import { Container, Input, Select, Typography } from '@otion-core/sandy'
import { SelectProps } from '@otion-core/sandy/build/components/Select/Select'
import { InputProps } from '@otion-core/sandy/build/components/Input/Input'

import * as S from './styles'
import PopupDatePicker from '../PopupDatePicker'

interface DetailPageInputFieldProps<T = Record<string, string | number | (string | number)[] | undefined | null>> {
  column?: number
  isEditMode?: boolean
  isBold?: boolean
  name: keyof T
  subfieldName?: keyof T
  label: string
  values: T
  errors?: FormikErrors<T>
  touched?: FormikTouched<T>
  format?: (value: any) => ReactNode // eslint-disable-line @typescript-eslint/no-explicit-any
  inputProps?: InputProps & React.RefAttributes<HTMLInputElement>
  selectProps?: SelectProps & React.RefAttributes<HTMLInputElement>
  disabled?: boolean
  isCalendar?: boolean
  validate?: any
}

const DetailPageInputField = <T extends unknown>(props: DetailPageInputFieldProps<T>) => {
  const isSelect = !!props.selectProps
  const isCalendar = !!props.isCalendar
  const currentValue: any = props.values[props.name] // eslint-disable-line @typescript-eslint/no-explicit-any
  let valueText = currentValue
  if (isSelect) {
    if (Array.isArray(currentValue)) {
      const selectedOptions = props.selectProps?.options?.filter(option => currentValue.includes(option.value))
      valueText = selectedOptions?.map(option => option.text).join(', ')
    } else {
      const selectedOption = props.selectProps?.options?.find(option => option.value === currentValue?.toString())
      valueText = selectedOption?.text
    }
  }
  if (props.format) {
    valueText = props.format(currentValue)
  }

  const hasErrors = props.errors?.[props.name]
  const isTouched = props.touched ? props.touched?.[props.name] : true
  const hasErrorsSubfield = props.subfieldName ? props.errors?.[props.subfieldName] : false
  const isTouchedSubfield = props.subfieldName && props.touched ? props.touched?.[props.subfieldName] : true
  const inputProps = props.inputProps?.multiline ? {} : { style: { lineHeight: '38px' } }

  return (
    <Container
      flex
      style={{
        gridColumnStart: props.column ? props.column : undefined,
        pointerEvents: props.disabled ? 'none' : undefined,
        opacity: props.disabled ? '0.5' : undefined
      }}
    >
      <S.OptionName
        size='medium'
        weight={props.isBold ? 'bold' : 'medium'}
        inline
        style={props.column ? undefined : { marginTop: 8 }}
      >
        {props.label}
      </S.OptionName>

      <Container fullWidth flex style={{ gap: 10, marginTop: props.column ? undefined : 8 }}>
        {props.isEditMode ? (
          <Container fullWidth>
            {Boolean(hasErrors) && Boolean(isTouched) && (
              <Typography color='red' size='small' style={{ marginLeft: 13 }}>
                {hasErrors as string}
              </Typography>
            )}
            {isSelect ? (
              <Field
                name={props.name}
                as={Select}
                error={Boolean(hasErrors) && Boolean(isTouched)}
                width='full'
                inputProps={inputProps}
                validate={props.validate}
                disabled={props.disabled}
                {...props.selectProps}
              />
            ) : isCalendar ? (
              <Field
                name={props.name}
                component={PopupDatePicker}
                error={Boolean(hasErrors) && Boolean(isTouched)}
                disabled={props.disabled}
              />
            ) : (
              <Field
                name={props.name}
                as={Input}
                error={Boolean(hasErrors) && Boolean(isTouched)}
                width='full'
                inputProps={inputProps}
                validate={props.validate}
                disabled={props.disabled}
                {...props.inputProps}
              />
            )}
          </Container>
        ) : (
          <Typography as='span' size='medium' weight={props.isBold ? 'bold' : 'medium'} inline>
            {valueText !== undefined && valueText !== null && valueText !== '' ? valueText : '-'}
          </Typography>
        )}

        {props.subfieldName ? (
          props.isEditMode ? (
            <Container fullWidth>
              {Boolean(hasErrorsSubfield) && Boolean(isTouchedSubfield) && (
                <Typography color='red' size='small' style={{ marginLeft: 13 }}>
                  {hasErrorsSubfield as string}
                </Typography>
              )}
              <Field
                name={props.subfieldName}
                as={Input}
                error={Boolean(hasErrorsSubfield) && Boolean(isTouchedSubfield)}
                width='full'
                inputProps={inputProps}
                validate={props.validate}
                disabled={props.disabled}
                {...props.inputProps}
              />
            </Container>
          ) : (
            <React.Fragment>
              {props.format ? null : (
                <Typography as='span' size='medium' weight={props.isBold ? 'bold' : 'medium'} inline>
                  {props.values[props.subfieldName as never] || '-'}
                </Typography>
              )}
            </React.Fragment>
          )
        ) : null}
      </Container>
    </Container>
  )
}

DetailPageInputField.displayName = 'DetailPageInputField'
export default DetailPageInputField
