import React, { ReactNode } from 'react'
import { ColorsType, Container, Grid, HorizontalScroll, Typography } from '@otion-core/sandy'

import * as S from './styles'

interface Tab {
  tabIndex: number
  icon: ReactNode
  text: string
  name?: string
  disabled?: boolean
}

interface SubCategoryTabProps {
  tabs: Tab[]
  active?: number
  onChange: (index: number) => void
  color: ColorsType
}

const SubCategoryTab = (props: SubCategoryTabProps) => {
  const { tabs, active, color, onChange } = props

  return (
    <Container fullWidth>
      <HorizontalScroll>
        {tabs.map(tab => (
          <Grid.Item key={tab.tabIndex}>
            <S.Toolbox
              backgroundColor={active === tab.tabIndex ? `${color}.light` : 'white'}
              key={tab.tabIndex}
              active={active === tab.tabIndex}
              icon={tab.icon}
              iconColor='black'
              className={typeof tab.icon === 'string' ? 'no-svg-stroke' : ''}
              text={
                <Typography variant='heading' size='small' weight='medium' inline color='black'>
                  {tab.text}
                </Typography>
              }
              size='large'
              onClick={() => onChange(tab.tabIndex)}
              disabled={tab.disabled}
            />
          </Grid.Item>
        ))}
      </HorizontalScroll>
    </Container>
  )
}

SubCategoryTab.displayName = 'SubCategoryTab'
export default SubCategoryTab
