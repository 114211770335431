import { IPodFilter, TableSort, IPod, IPodDetails, PromiseAction, CreatePodPayload } from '../../shared/interfaces'
import * as PodApi from '../../apis/pod.api'

export const GET_POD_TRIGGERED = 'GET_POD_TRIGGERED'
export const GET_POD_SUCCESS = 'GET_POD_SUCCESS'
export const GET_POD_FAILURE = 'GET_POD_FAILURE'

export function getPod(filter: IPodFilter, sort: TableSort<IPod> | undefined, page: number, size = 10): PromiseAction {
  const promise = PodApi.getAllPod(filter, sort, page, size)
  return {
    onRequest: GET_POD_TRIGGERED,
    onSuccess: GET_POD_SUCCESS,
    onFailure: GET_POD_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

export const GET_POD_DETAIL_TRIGGERED = 'GET_POD_DETAIL_TRIGGERED'
export const GET_POD_DETAIL_SUCCESS = 'GET_POD_DETAIL_SUCCESS'
export const GET_POD_DETAIL_FAILURE = 'GET_POD_DETAIL_FAILURE'

let getPODDetailController: AbortController
export function getPodDetail(podId: number | string): PromiseAction {
  if (getPODDetailController) getPODDetailController.abort()
  getPODDetailController = new AbortController()
  const promise = PodApi.getPodDetail(podId, getPODDetailController.signal)
  return {
    onRequest: GET_POD_DETAIL_TRIGGERED,
    onSuccess: GET_POD_DETAIL_SUCCESS,
    onFailure: GET_POD_DETAIL_FAILURE,
    promise,
    payload: { podId }
  }
}

export const UPDATE_POD_DETAIL_TRIGGERED = 'UPDATE_POD_DETAIL_TRIGGERED'
export const UPDATE_POD_DETAIL_SUCCESS = 'UPDATE_POD_DETAIL_SUCCESS'
export const UPDATE_POD_DETAIL_FAILURE = 'UPDATE_POD_DETAIL_FAILURE'

export function updatePodDetail(podId: number | string, values: Partial<IPodDetails>): PromiseAction {
  const promise = PodApi.updatePodDetail(podId, values)
  return {
    onRequest: UPDATE_POD_DETAIL_TRIGGERED,
    onSuccess: UPDATE_POD_DETAIL_SUCCESS,
    onFailure: UPDATE_POD_DETAIL_FAILURE,
    promise,
    payload: { podId, values }
  }
}

export const CREATE_POD_TRIGGERED = 'CREATE_POD_TRIGGERED'
export const CREATE_POD_SUCCESS = 'CREATE_POD_SUCCESS'
export const CREATE_POD_FAILURE = 'CREATE_POD_FAILURE'

export function createPodDetail(values: CreatePodPayload): PromiseAction {
  const promise = PodApi.createPod(values)
  return {
    onRequest: CREATE_POD_TRIGGERED,
    onSuccess: CREATE_POD_SUCCESS,
    onFailure: CREATE_POD_FAILURE,
    promise,
    payload: { values }
  }
}

export const GET_POD_ACTIONS_TRIGGERED = 'GET_POD_ACTIONS_TRIGGERED'
export const GET_POD_ACTIONS_SUCCESS = 'GET_POD_ACTIONS_SUCCESS'
export const GET_POD_ACTIONS_FAILURE = 'GET_POD_ACTIONS_FAILURE'

export const getPodActions = () => {
  const promise = PodApi.getPodActions()
  return {
    onRequest: GET_POD_ACTIONS_TRIGGERED,
    onSuccess: GET_POD_ACTIONS_SUCCESS,
    onFailure: GET_POD_ACTIONS_FAILURE,
    promise
  }
}

export const GET_AVAILABLE_POD_LIST_TRIGGERED = 'GET_AVAILABLE_POD_LIST_TRIGGERED'
export const GET_AVAILABLE_POD_LIST_SUCCESS = 'GET_AVAILABLE_POD_LIST_SUCCESS'
export const GET_AVAILABLE_POD_LIST_FAILURE = 'GET_AVAILABLE_POD_LIST_FAILURE'

export const getAvailablePodList = (fromDate: string): PromiseAction => {
  const promise = PodApi.getAvailablePodList(fromDate)
  return {
    onRequest: GET_AVAILABLE_POD_LIST_TRIGGERED,
    onSuccess: GET_AVAILABLE_POD_LIST_SUCCESS,
    onFailure: GET_AVAILABLE_POD_LIST_FAILURE,
    promise
  }
}

export const GET_POD_MONTHLY_VOLUMES_TRIGGERED = 'GET_POD_MONTHLY_VOLUMES_TRIGGERED'
export const GET_POD_MONTHLY_VOLUMES_SUCCESS = 'GET_POD_MONTHLY_VOLUMES_SUCCESS'
export const GET_POD_MONTHLY_VOLUMES_FAILURE = 'GET_POD_MONTHLY_VOLUMES_FAILURE'

export function getPodMonthlyValues(podId: number | string): PromiseAction {
  const promise = PodApi.getPodMonthlyValues(podId)
  return {
    onRequest: GET_POD_MONTHLY_VOLUMES_TRIGGERED,
    onSuccess: GET_POD_MONTHLY_VOLUMES_SUCCESS,
    onFailure: GET_POD_MONTHLY_VOLUMES_FAILURE,
    promise,
    payload: { podId }
  }
}

export const POST_REPAIR_POD_MZM_TRIGGERED = 'POST_REPAIR_POD_MZM_TRIGGERED'
export const POST_REPAIR_POD_MZM_SUCCESS = 'POST_REPAIR_POD_MZM_SUCCESS'
export const POST_REPAIR_POD_MZM_FAILURE = 'POST_REPAIR_POD_MZM_FAILURE'

export const postRepairPodMzm = (id: number): PromiseAction => {
  const promise = PodApi.repairMzm(id)
  return {
    onRequest: POST_REPAIR_POD_MZM_TRIGGERED,
    onSuccess: POST_REPAIR_POD_MZM_SUCCESS,
    onFailure: POST_REPAIR_POD_MZM_FAILURE,
    promise
  }
}

// Export bank accounts list in xls
export const EXPORT_PODS_TRIGGERED = 'EXPORT_PODS_TRIGGERED'
export const EXPORT_PODS_SUCCESS = 'EXPORT_PODS_SUCCESS'
export const EXPORT_PODS_FAILURE = 'EXPORT_PODS_FAILURE'

export function exportPods(filter: IPodFilter): PromiseAction {
  const promise = PodApi.exportPods(filter)
  return {
    onRequest: EXPORT_PODS_TRIGGERED,
    onSuccess: EXPORT_PODS_SUCCESS,
    onFailure: EXPORT_PODS_FAILURE,
    promise
  }
}
