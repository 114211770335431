import { AnyAction } from 'redux'

import { IMessage, IMessagesState, IMailbox, IPaginationResponse } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IMessagesState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  details: undefined,
  mailbox: undefined
}

const messagesReducer = (state = initialState, action: AnyAction): IMessagesState => {
  switch (action.type) {
    case actionTypes.GET_MESSAGES_SUCCESS: {
      const response: IPaginationResponse<IMessage> = action.response?.data

      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_MESSAGE_DETAILS_SUCCESS: {
      const response: IMessage = action.response?.data
      return {
        ...state,
        details: response
      }
    }

    case actionTypes.GET_MAILBOX_SUCCESS: {
      const response: IMailbox = action.response?.data
      return {
        ...state,
        mailbox: response
      }
    }

    case actionTypes.UPLOAD_XML_ATTACHMENT_SUCCESS: {
      const response: IMessage = action.response?.data
      if (!response?.id) return state
      return {
        ...state,
        list: {
          ...state.list,
          data: {
            ...state.list.data,
            items: state.list.data.items?.map(item => (item.id === response.id ? response : item))
          }
        }
      }
    }

    default:
      return state
  }
}

export default messagesReducer
