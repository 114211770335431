import * as PaymentInvoicesApi from '../../apis/paymentInvoices.api'
import {
  IPaymentInvoice,
  IPaymentInvoiceCreatePayload,
  IPaymentInvoicesFilter,
  PromiseAction,
  TableSort
} from '../../shared/interfaces'

// Get paginated payment invoices
export const GET_ALL_PAYMENT_INVOICES_TRIGGERED = 'GET_ALL_PAYMENT_INVOICES_TRIGGERED'
export const GET_ALL_PAYMENT_INVOICES_SUCCESS = 'GET_ALL_PAYMENT_INVOICES_SUCCESS'
export const GET_ALL_PAYMENT_INVOICES_FAILURE = 'GET_ALL_PAYMENT_INVOICES_FAILURE'

export function getAllPaymentInvoices(
  filter: IPaymentInvoicesFilter,
  sort: TableSort<IPaymentInvoice> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = PaymentInvoicesApi.getAllPaymentInvoices(filter, sort, page, size)
  return {
    onRequest: GET_ALL_PAYMENT_INVOICES_TRIGGERED,
    onSuccess: GET_ALL_PAYMENT_INVOICES_SUCCESS,
    onFailure: GET_ALL_PAYMENT_INVOICES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get payment invoice details
export const GET_PAYMENT_INVOICE_DETAIL_TRIGGERED = 'GET_PAYMENT_INVOICE_DETAIL_TRIGGERED'
export const GET_PAYMENT_INVOICE_DETAIL_SUCCESS = 'GET_PAYMENT_INVOICE_DETAIL_SUCCESS'
export const GET_PAYMENT_INVOICE_DETAIL_FAILURE = 'GET_PAYMENT_INVOICE_DETAIL_FAILURE'

export function getPaymentInvoiceDetail(invoiceId: number): PromiseAction {
  const promise = PaymentInvoicesApi.getPaymentInvoiceDetail(invoiceId)
  return {
    onRequest: GET_PAYMENT_INVOICE_DETAIL_TRIGGERED,
    onSuccess: GET_PAYMENT_INVOICE_DETAIL_SUCCESS,
    onFailure: GET_PAYMENT_INVOICE_DETAIL_FAILURE,
    promise,
    payload: { invoiceId }
  }
}

// Download payment invoice consumption XLS
export const GET_VFA_CONSUMPTION_XLS_TRIGGERED = 'GET_VFA_CONSUMPTION_XLS_TRIGGERED'
export const GET_VFA_CONSUMPTION_XLS_SUCCESS = 'GET_VFA_CONSUMPTION_XLS_SUCCESS'
export const GET_VFA_CONSUMPTION_XLS_FAILURE = 'GET_VFA_CONSUMPTION_XLS_FAILURE'

export function getConsumptionXls(eic: string, dateFrom: string, dateTo: string): PromiseAction {
  const promise = PaymentInvoicesApi.getConsumptionXls(eic, dateFrom, dateTo)
  return {
    onRequest: GET_VFA_CONSUMPTION_XLS_TRIGGERED,
    onSuccess: GET_VFA_CONSUMPTION_XLS_SUCCESS,
    onFailure: GET_VFA_CONSUMPTION_XLS_FAILURE,
    promise
  }
}

// Create new payment invoice manually
export const CREATE_PAYMENT_INVOICE_TRIGGERED = 'CREATE_PAYMENT_INVOICE_TRIGGERED'
export const CREATE_PAYMENT_INVOICE_SUCCESS = 'CREATE_PAYMENT_INVOICE_SUCCESS'
export const CREATE_PAYMENT_INVOICE_FAILURE = 'CREATE_PAYMENT_INVOICE_FAILURE'

export function createPaymentInvoice(payload: IPaymentInvoiceCreatePayload): PromiseAction {
  const promise = PaymentInvoicesApi.createPaymentInvoice(payload)
  return {
    onRequest: CREATE_PAYMENT_INVOICE_TRIGGERED,
    onSuccess: CREATE_PAYMENT_INVOICE_SUCCESS,
    onFailure: CREATE_PAYMENT_INVOICE_FAILURE,
    promise
  }
}

// Export invoices list in xls
export const EXPORT_PAYMENT_INVOICES_TRIGGERED = 'EXPORT_PAYMENT_INVOICES_TRIGGERED'
export const EXPORT_PAYMENT_INVOICES_SUCCESS = 'EXPORT_PAYMENT_INVOICES_SUCCESS'
export const EXPORT_PAYMENT_INVOICES_FAILURE = 'EXPORT_PAYMENT_INVOICES_FAILURE'

export function exportPaymentInvoices(filter: IPaymentInvoicesFilter): PromiseAction {
  const promise = PaymentInvoicesApi.exportPaymentInvoices(filter)
  return {
    onRequest: EXPORT_PAYMENT_INVOICES_TRIGGERED,
    onSuccess: EXPORT_PAYMENT_INVOICES_SUCCESS,
    onFailure: EXPORT_PAYMENT_INVOICES_FAILURE,
    promise
  }
}
