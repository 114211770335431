import {
  IMessage,
  IMessageFilter,
  IPaginationResponse,
  IMailbox,
  TableSort,
  MessageFetchType,
  IUploadAttachmentPayload,
  IMassUploadAttachmentPayload,
  IFile
} from 'src/shared/interfaces'
import { generatePaginationPayload } from 'src/lib/paginationPayload'

import Http from './api'

export const getMessages = (filter: IMessageFilter, sort: TableSort<IMessage> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter: {
      type: filter.type,
      status: filter.status,
      datetime: [filter.datetime_from, filter.datetime_to]
    },
    searchBy: ['subject', 'eic'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IMessage>>('/messages', payload)
}

export const getMessageDetails = (id: number) => {
  return Http.get<IMessage>(`/messages/${id}`)
}

export const getMessageStatuses = () => {
  return Http.get<string[]>('/messages/statuses')
}

export const getXmlAttachment = (id: number) => {
  return Http.get<IFile>(`/files/${id}`)
}

export const getMailbox = (type: MessageFetchType) => {
  return Http.get<IMailbox>('/mailbox', { type })
}

export const uploadAttachment = (payload: IUploadAttachmentPayload) => {
  return Http.post<IMailbox>('/mailbox/upload-xml-attachment', payload)
}

export const massUploadAttachment = (payload: IMassUploadAttachmentPayload[]) => {
  return Http.post<unknown>('/mailbox/mass-upload-xml-attachment', payload)
}
