import { PromiseAction } from 'src/shared/interfaces'

import * as VatRatesApi from '../../apis/vat-rates.api'

// Get electricity vat rates
export const GET_ELECTRICITY_VAT_RATES_TRIGGERED = 'GET_ELECTRICITY_VAT_RATES_TRIGGERED'
export const GET_ELECTRICITY_VAT_RATES_SUCCESS = 'GET_ELECTRICITY_VAT_RATES_SUCCESS'
export const GET_ELECTRICITY_VAT_RATES_FAILURE = 'GET_ELECTRICITY_VAT_RATES_FAILURE'

export function getElectricityVatRates(date: Date): PromiseAction {
  const promise = VatRatesApi.getElectricityVatRates(date)
  return {
    onRequest: GET_ELECTRICITY_VAT_RATES_TRIGGERED,
    onSuccess: GET_ELECTRICITY_VAT_RATES_SUCCESS,
    onFailure: GET_ELECTRICITY_VAT_RATES_FAILURE,
    promise,
    payload: { date }
  }
}

// Get gas vat rates
export const GET_GAS_VAT_RATES_TRIGGERED = 'GET_GAS_VAT_RATES_TRIGGERED'
export const GET_GAS_VAT_RATES_SUCCESS = 'GET_GAS_VAT_RATES_SUCCESS'
export const GET_GAS_VAT_RATES_FAILURE = 'GET_GAS_VAT_RATES_FAILURE'

export function getGasVatRates(date: Date): PromiseAction {
  const promise = VatRatesApi.getGasVatRates(date)
  return {
    onRequest: GET_GAS_VAT_RATES_TRIGGERED,
    onSuccess: GET_GAS_VAT_RATES_SUCCESS,
    onFailure: GET_GAS_VAT_RATES_FAILURE,
    promise,
    payload: { date }
  }
}
