import * as ActionApi from '../../apis/action.api'
import {
  ActionTypesEnum,
  IAction,
  IActionFilter,
  IActionPayload,
  PromiseAction,
  TableSort
} from '../../shared/interfaces'

// Statuses

export const GET_ACTION_STATUSES_TRIGGERED = 'GET_ACTION_STATUSES_TRIGGERED'
export const GET_ACTION_STATUSES_SUCCESS = 'GET_ACTION_STATUSES_SUCCESS'
export const GET_ACTION_STATUSES_FAILURE = 'GET_ACTION_STATUSES_FAILURE'

export function getActionStatuses(): PromiseAction {
  const promise = ActionApi.getActionStatuses()
  return {
    onRequest: GET_ACTION_STATUSES_TRIGGERED,
    onSuccess: GET_ACTION_STATUSES_SUCCESS,
    onFailure: GET_ACTION_STATUSES_FAILURE,
    promise
  }
}

// Get actions

export const GET_ELECTRICITY_ACTIONS_TRIGGERED = 'GET_ELECTRICITY_ACTIONS_TRIGGERED'
export const GET_ELECTRICITY_ACTIONS_SUCCESS = 'GET_ELECTRICITY_ACTIONS_SUCCESS'
export const GET_ELECTRICITY_ACTIONS_FAILURE = 'GET_ELECTRICITY_ACTIONS_FAILURE'

export const getElectricityActions =
  (type: ActionTypesEnum) =>
  (filter: IActionFilter, sort: TableSort<IAction> | undefined, page: number, size = 10): PromiseAction => {
    const promise = ActionApi.getElectricityActions(type, filter, sort, page, size)
    return {
      onRequest: GET_ELECTRICITY_ACTIONS_TRIGGERED,
      onSuccess: GET_ELECTRICITY_ACTIONS_SUCCESS,
      onFailure: GET_ELECTRICITY_ACTIONS_FAILURE,
      promise,
      payload: { type, filter, page, size }
    }
  }

// Create actions

export const CREATE_EIC_ACTION_TRIGGERED = 'CREATE_EIC_ACTION_TRIGGERED'
export const CREATE_EIC_ACTION_SUCCESS = 'CREATE_EIC_ACTION_SUCCESS'
export const CREATE_EIC_ACTION_FAILURE = 'CREATE_EIC_ACTION_FAILURE'

export function createEicAction(messageCode: string, payload: IActionPayload): PromiseAction {
  const promise = ActionApi.createEicAction(messageCode, payload)
  return {
    onRequest: CREATE_EIC_ACTION_TRIGGERED,
    onSuccess: CREATE_EIC_ACTION_SUCCESS,
    onFailure: CREATE_EIC_ACTION_FAILURE,
    promise,
    payload: { payload }
  }
}

export const GET_DISTRIBUTION_ACTION_DETAILS_TRIGGERED = 'GET_DISTRIBUTION_ACTION_DETAILS_TRIGGERED'
export const GET_DISTRIBUTION_ACTION_DETAILS_SUCCESS = 'GET_DISTRIBUTION_ACTION_DETAILS_SUCCESS'
export const GET_DISTRIBUTION_ACTION_DETAILS_FAILURE = 'GET_DISTRIBUTION_ACTION_DETAILS_FAILURE'

export function getDistributionActionDetails(id: number): PromiseAction {
  const promise = ActionApi.getDistributionActionDetails(id)
  return {
    onRequest: GET_DISTRIBUTION_ACTION_DETAILS_TRIGGERED,
    onSuccess: GET_DISTRIBUTION_ACTION_DETAILS_SUCCESS,
    onFailure: GET_DISTRIBUTION_ACTION_DETAILS_FAILURE,
    promise,
    payload: { id }
  }
}
