import { AnyAction } from 'redux'
import { IElectricityVatRates, IGasVatRates, IVatRatesState } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IVatRatesState = {
  electricityVatRates: [],
  gasVatRates: []
}

const vatRatesReducer = (state = initialState, action: AnyAction): IVatRatesState => {
  switch (action.type) {
    case actionTypes.GET_ELECTRICITY_VAT_RATES_SUCCESS: {
      const electricityVatRates: IElectricityVatRates[] = action.response?.data
      return { ...state, electricityVatRates }
    }

    case actionTypes.GET_GAS_VAT_RATES_SUCCESS: {
      const gasVatRates: IGasVatRates[] = action.response?.data
      return { ...state, gasVatRates }
    }

    default:
      return state
  }
}

export default vatRatesReducer
