import styled from 'styled-components'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Toolbox as SToolbox } from '@otion-core/sandy/build/components'
import { getBPHelpers } from '@otion-core/sandy'

const Toolbox = styled(SToolbox)<{ active?: boolean; disabled?: boolean }>`
  cursor: pointer;
  margin: auto;
  height: 180px !important;
  width: 180px !important;

  > a > div {
    box-shadow: none;
  }

  &:not(.no-svg-stroke) {
    svg {
      fill: ${({ theme }) => theme.colors.black.default} !important;
      stroke: ${({ theme }) => theme.colors.black.default} !important;
    }
  }

  h4 {
    text-align: center !important;
    color: ${({ theme }) => theme.colors.black.default} !important;
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
  `}

  ${props => getBPHelpers(props.theme.breakpoints).screens('small')} {
    height: 155px !important;
    width: 155px !important;
  }
`

const ToolboxSmall = styled(SToolbox)<{ active?: boolean; disabled?: boolean }>`
  border-bottom: 3px solid
    ${({ theme, color, active }) => (active ? theme.colors[color as never].default : 'rgba(0,0,0,.12)')};
  cursor: pointer;
  margin: 0 0 22px;
  height: 50px !important;
  width: 50px !important;

  div {
    background: transparent !important;
    box-shadow: none;
    border-radius: 6px !important;
  }

  svg {
    height: 28px;
  }

  &:not(.no-svg-stroke) {
    svg {
      fill: ${({ theme, color, active }) =>
        active ? theme.colors.black.default : theme.colors[color as never].default} !important;
      stroke: ${({ theme, color, active }) =>
        active ? theme.colors.black.default : theme.colors[color as never].default} !important;
    }
  }

  h4 {
    text-align: center !important;
    color: ${({ theme }) => theme.colors.black.default} !important;
  }

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    
    svg {opacity: 0.4;}
  `}
`

export { Toolbox, ToolboxSmall }
