import { AnyAction } from 'redux'

import { IPaginationResponse, IMeterValuesState, IMeterValues, IMeterValuesDetails } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IMeterValuesState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  details: []
}

const meterValuesReducer = (state = initialState, action: AnyAction): IMeterValuesState => {
  switch (action.type) {
    case actionTypes.GET_METER_VALUES_SUCCESS: {
      const response: IPaginationResponse<IMeterValues> = action.response?.data
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_METER_VALUES_DETAILS_SUCCESS: {
      const response: IMeterValuesDetails[] = action.response?.data

      return {
        ...state,
        details: response
      }
    }

    default:
      return state
  }
}

export default meterValuesReducer
