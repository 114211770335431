import moment from 'moment'
import {
  IPaginationResponse,
  IPostMonthlyVolumes,
  IProformaInvoiceDetails,
  TableSort,
  IProformaInvoiceGas,
  IPostProformaInvoicesGasGenerate,
  IProformaInvoicesGasFilter
} from 'src/shared/interfaces'
import { DATE_FORMAT_DB } from 'src/shared/constants'

import { generatePaginationPayload } from '../lib/paginationPayload'
import Http from './api'

export const getAllProformaInvoicesGas = (
  filter: IProformaInvoicesGasFilter,
  sort: TableSort<IProformaInvoiceGas> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      expected_delivery_start: [
        filter.expected_delivery_start ? moment(filter.expected_delivery_start).format(DATE_FORMAT_DB) : undefined,
        filter.expected_delivery_end ? moment(filter.expected_delivery_end).format(DATE_FORMAT_DB) : undefined
      ]
    },
    searchBy: ['customer', 'pod'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IProformaInvoiceGas>>('/proforma-invoices-gas', payload)
}

export const getProformaInvoiceGasDetail = (id: number) => {
  return Http.get<IProformaInvoiceDetails>(`/proforma-invoices-gas/${id}`)
}
export const getProformaInvoicePdf = (id: number) => {
  return Http.get<unknown>(`/proforma-invoices-gas/pdf/${id}`)
}

export const postProformaInvoicesGasGenerate = (payload: IPostProformaInvoicesGasGenerate) => {
  return Http.post<never>(`/proforma-invoices-gas/generate`, payload)
}

export const postMonthlyVolumes = (payload: IPostMonthlyVolumes) => {
  return Http.post<never>(`/monthly-volumes/create-multiple`, payload)
}

export const exportProformaInvoicesGas = (filter: IProformaInvoicesGasFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      expected_delivery_start: [
        filter.expected_delivery_start ? moment(filter.expected_delivery_start).format(DATE_FORMAT_DB) : undefined,
        filter.expected_delivery_end ? moment(filter.expected_delivery_end).format(DATE_FORMAT_DB) : undefined
      ]
    },
    searchBy: ['customer', 'pod'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/proforma-invoices-gas/xls/export/?filter=${JSON.stringify(payload)}`)
}
