export interface IMeterValuesDetails {
  id: number
  pod_id: number
  gas_meter: string
  gas_meter_value: string
  gas_meter_value_002: string
  gas_meter_value_003: string
  gas_meter_value_004: any
  gas_meter_value_005: any
  gas_meter_value_006: any
  date_of_reading: string
  planned_date_of_reading: string
  reason: string
  planned_type_of_reading: string
  status: string
  type_of_reading: string
  activity_status: string
  pod_pod: string
  pod_delivery_start: string
  pod_delivery_end: string
  pod_status: string
  pod_address_street: string
  pod_address_number: string
  pod_address_zip_code: string
  pod_address_city: string
  pod_rate: string
  pod_created_at: string
  pod_created_by: number
  pod_dmm: number
  pod_updated_at: string
  pod_updated_by: number
  pod_tdd: any
  pod_distribution_system: string
}

export interface IMeterValues {
  id: number
  pod_id: number
  pod_pod: string
  pod_delivery_start: string
  pod_delivery_end: string
  pod_status: string
  pod_address_street: string
  pod_address_number: string
  pod_address_zip_code: string
  pod_address_city: string
  pod_rate: string
  pod_created_at: string
  pod_created_by: number
  pod_dmm: number
  pod_updated_at: string
  pod_updated_by: number
  pod_tdd: any
  pod_distribution_system: string
  gas_meter: string
}

export interface IMeterValuesFilter {
  search?: string
  date_of_reading_from?: string
  date_of_reading_to?: string
  status?: number
}

export interface IMeterValuesDetailsFilter {
  search?: string
  date_of_reading_from?: string
  date_of_reading_to?: string
  status?: number
}

export const GasMeterReasonEnum = {
  '09': 'Priebežný odpočet bez zúčtovania'
}

export const GasMeterTypeOfReadingEnum = {
  '01': '01'
}
