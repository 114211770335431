import React, { ReactNode } from 'react'
import { Button, Container, Typography, ContainerSpacingType, ButtonSizesType, ColorsType } from '@otion-core/sandy'

interface ButtonWhiteProps {
  active?: boolean
  onClick: () => void
  children: ReactNode
  rightAdornment?: ReactNode
  textAlign?: 'left' | 'center'
  disabled?: boolean
  top?: ContainerSpacingType
  right?: ContainerSpacingType
  bottom?: ContainerSpacingType
  left?: ContainerSpacingType
  size?: ButtonSizesType
  fullWidth?: boolean
  stopPropagation?: boolean
  color: ColorsType
  style?: any
}

const ButtonWhite = (props: ButtonWhiteProps) => {
  const isLeftAligned = props.textAlign === 'left'

  return (
    <Container top={props.top} left={props.left} bottom={props.bottom} right={props.right} fullWidth={props.fullWidth}>
      <Button
        color={props.active ? `${props.color}.light` : 'white'}
        size={props.size}
        style={{
          justifyContent: isLeftAligned ? 'flex-start' : 'center',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
        }}
        onClick={props.onClick}
        disabled={props.disabled}
        stopPropagation={props.stopPropagation}
      >
        <Container
          left={isLeftAligned ? 'xsmall' : undefined}
          flex
          justifyContent={isLeftAligned ? 'space-between' : 'center'}
          alignItems='center'
          fullWidth
        >
          <Typography
            as='span'
            size='medium'
            weight='semibold'
            color={props.disabled ? 'white' : 'black'}
            style={props.style}
          >
            {props.children}
          </Typography>
          {props.rightAdornment || null}
        </Container>
      </Button>
    </Container>
  )
}

ButtonWhite.defaultProps = {
  textAlign: 'left',
  size: 'xsmall',
  fullWidth: true
}

ButtonWhite.displayName = 'ButtonWhite'
export default ButtonWhite
