import * as ConsumptionsApi from '../../apis/consumptions.api'
import {
  IConsumption,
  IConsumptionFilter,
  IGetConsumptionByEicPayload,
  PromiseAction,
  TableSort
} from '../../shared/interfaces'

// Fetch Consumption list
export const GET_CONSUMPTIONS_TRIGGERED = 'GET_CONSUMPTIONS_TRIGGERED'
export const GET_CONSUMPTIONS_SUCCESS = 'GET_CONSUMPTIONS_SUCCESS'
export const GET_CONSUMPTIONS_FAILURE = 'GET_CONSUMPTIONS_FAILURE'

export function getConsumptions(
  filter: IConsumptionFilter,
  sort: TableSort<IConsumption> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = ConsumptionsApi.getAllConsumption(filter, sort, page, size)
  return {
    onRequest: GET_CONSUMPTIONS_TRIGGERED,
    onSuccess: GET_CONSUMPTIONS_SUCCESS,
    onFailure: GET_CONSUMPTIONS_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Reload Consumptions data
export const RELOAD_CONSUMPTIONS_TRIGGERED = 'RELOAD_CONSUMPTIONS_TRIGGERED'
export const RELOAD_CONSUMPTIONS_SUCCESS = 'RELOAD_CONSUMPTIONS_SUCCESS'
export const RELOAD_CONSUMPTIONS_FAILURE = 'RELOAD_CONSUMPTIONS_FAILURE'

export function reloadOkteConsumptions(): PromiseAction {
  const promise = ConsumptionsApi.getReloadOkteConsumption()
  return {
    onRequest: RELOAD_CONSUMPTIONS_TRIGGERED,
    onSuccess: RELOAD_CONSUMPTIONS_SUCCESS,
    onFailure: RELOAD_CONSUMPTIONS_FAILURE,
    promise
  }
}

// Fetch Consumption detail
export const GET_CONSUMPTION_BY_EIC_TRIGGERED = 'GET_CONSUMPTION_BY_EIC_TRIGGERED'
export const GET_CONSUMPTION_BY_EIC_SUCCESS = 'GET_CONSUMPTION_BY_EIC_SUCCESS'
export const GET_CONSUMPTION_BY_EIC_FAILURE = 'GET_CONSUMPTION_BY_EIC_FAILURE'

export function getConsumptionByEic(eic: string, params: IGetConsumptionByEicPayload): PromiseAction {
  const promise = ConsumptionsApi.getConsumptionByEic(eic, params)
  return {
    onRequest: GET_CONSUMPTION_BY_EIC_TRIGGERED,
    onSuccess: GET_CONSUMPTION_BY_EIC_SUCCESS,
    onFailure: GET_CONSUMPTION_BY_EIC_FAILURE,
    promise
  }
}
