import styled from 'styled-components'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Toolbox as SToolbox } from '@otion-core/sandy/build/components'
import { getBPHelpers } from '@otion-core/sandy'

const Toolbox = styled(SToolbox)<{ active?: boolean; disabled?: boolean }>`
  cursor: pointer;
  margin: auto;
  height: 180px !important;
  width: 180px !important;

  > div {
    box-shadow: none;
  }

  &:not(.no-svg-stroke) {
    svg {
      fill: ${({ iconColor, theme }) => theme.colors[iconColor as never].default} !important;
      stroke: ${({ iconColor, theme }) => theme.colors[iconColor as never].default} !important;
    }
  }

  h4 {
    text-align: center !important;
  }
  ${props =>
    props.disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
  `}

  ${props => getBPHelpers(props.theme.breakpoints).screens('small')} {
    height: 155px !important;
    width: 155px !important;
  }
`

export { Toolbox }
