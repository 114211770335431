// eslint-disable-next-line no-shadow
export enum VatRateTypeElectricity {
  ELECTRICITY_SUPPLY = 'electricity_supply',
  FIXED_MONTHLY_FEE = 'fixed_monthly_fee',
  ELECTRICITY_DISTRIBUTION_FEE = 'electricity_distribution_fee',
  TARIFF_FOR_LOSSES = 'tariff_for_losses',
  RESERVED_CAPACITY_FEE = 'reserved_capacity_fee',
  FIXED_MONTHLY_TARIFF = 'fixed_monthly_tariff',
  TRANSFER_TO_NNF_FEE = 'transfer_to_nnf_fee',
  SYSTEM_OPERATION_FEE = 'system_operation_fee',
  SYSTEM_SERVICES_TARIFF = 'system_services_tariff',
  OTHER_FEES = 'other_fees',
  EXCISE_DUTY = 'excise_duty'
}

// eslint-disable-next-line no-shadow
export enum VatRateTypeGas {
  GAS_SUPPLY = 'gas_supply',
  FIXED_MONTHLY_FEE = 'fixed_monthly_fee',
  STRUCTURING_VARIABLE = 'structuring_variable',
  STRUCTURING_FIXED = 'structuring_fixed',
  TRANSPORT_VARIABLE = 'transport_variable',
  TRANSPORT_FIXED = 'transport_fixed',
  STORAGE = 'storage',
  NOMINATIONS = 'nominations',
  OTHER_SERVICES_MONTHLY_FEE = 'other_services_monthly_fee',
  DISTRIBUTION_VARIABLE = 'distribution_variable',
  DISTRIBUTION_FIXED = 'distribution_fixed',
  DISTRIBUTION_CAPACITY_ENTRY = 'distribution_capacity_entry',
  DISTRIBUTION_CAPACITY_DAILY = 'distribution_capacity_daily',
  GAS_FOR_OPERATIONAL_PURPOSES = 'gas_for_operational_purposes',
  EXCISE_DUTY = 'excise_duty'
}

export interface IElectricityVatRates {
  id: number
  type: VatRateTypeElectricity
  rate: string
  valid_from: Date
  valid_until: Date
  created_at: Date
  updated_at: Date
  created_by: Date
  updated_by: Date
}

export interface IGasVatRates {
  id: number
  type: VatRateTypeGas
  rate: string
  valid_from: Date
  valid_until: Date
  created_at: Date
  updated_at: Date
  created_by: Date
  updated_by: Date
}
