import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { BaseLayout, BasePageLoader, Delay } from '@otion-core/sandy'

import { LogoutPage, NotFoundPage } from './pages'
import { ErrorBoundary, LayoutRoute, Loader } from './components'
import { user$ } from './redux/selectors'
import { hasToken, removeToken } from './utils'
import { getMe } from './redux/actions'
import { UserRole } from './shared/interfaces'
import UserDetailsPage from './pages/Settings/Users/UserDetails/UserDetails'

const Home = lazy(() => import('./pages/Home' /* webpackChunkName: "home" */))
const Login = lazy(() => import('./pages/Login' /* webpackChunkName: "login" */))
const ForgottenPassword = lazy(() => import('./pages/ForgottenPassword' /* webpackChunkName: "forgottenpassword" */)) // prettier-ignore
const ResetPassword = lazy(() => import('./pages/ResetPassword' /* webpackChunkName: "resetpassword" */)) // prettier-ignore
const TasksPage = lazy(() => import('./pages/Tasks' /* webpackChunkName: "tasks" */))
const NotificationPage = lazy(() => import('./pages/Notification' /* webpackChunkName: "notification" */))
const ApprovalsPage = lazy(() => import('./pages/Approvals' /* webpackChunkName: "approvals" */))
const WarningPage = lazy(() => import('./pages/Warning' /* webpackChunkName: "warning" */))
const Settings = lazy(() => import('./pages/Settings' /* webpackChunkName: "settings" */))
const Electricity = lazy(() => import('./pages/Electricity' /* webpackChunkName: "electricity" */))
const Administration = lazy(() => import('./pages/Administration' /* webpackChunkName: "administration" */))
const Partners = lazy(() => import('./pages/Administration/Partners' /* webpackChunkName: "administration" */))
const BackOffice = lazy(() => import('./pages/Administration/BackOffice' /* webpackChunkName: "administration" */))
const AdministrationTrade = lazy(() => import('./pages/Administration/Trade' /* webpackChunkName: "administration" */))
const Mediation = lazy(() => import('./pages/Administration/Mediation' /* webpackChunkName: "administration" */))
const Gas = lazy(() => import('./pages/Gas' /* webpackChunkName: "gas" */))
const GasPortfolio = lazy(() => import('./pages/Gas/Portfolio' /* webpackChunkName: "gas" */))
const GasDistribution = lazy(() => import('./pages/Gas/Distribution' /* webpackChunkName: "gas" */))
const GasInvoicing = lazy(() => import('./pages/Gas/Invoicing' /* webpackChunkName: "gas" */))
const GasMeterValues = lazy(() => import('./pages/Gas/MeterValues' /* webpackChunkName: "gas" */))
const GasTrading = lazy(() => import('./pages/Gas/Trading' /* webpackChunkName: "gas" */))
const Financials = lazy(() => import('./pages/Financials' /* webpackChunkName: "financials" */))
const FinancialsFinancial = lazy(() => import('./pages/Financials/Financial' /* webpackChunkName: "financials" */))
const FinancialReporting = lazy(() => import('./pages/Financials/FinancialReporting' /* webpackChunkName: "financials" */)) // prettier-ignore
const FinancialOther = lazy(() => import('./pages/Financials/Other' /* webpackChunkName: "financials" */))
const Trade = lazy(() => import('./pages/Trade' /* webpackChunkName: "trade" */))
const TradeOffers = lazy(() => import('./pages/Trade/Offers' /* webpackChunkName: "trade" */))
const TradeDrafts = lazy(() => import('./pages/Trade/Drafts' /* webpackChunkName: "trade" */))
const TradeDatabase = lazy(() => import('./pages/Trade/Database' /* webpackChunkName: "trade" */))
const TradeReports = lazy(() => import('./pages/Trade/Reports' /* webpackChunkName: "trade" */))
const ElectricityPortfolio = lazy(() => import('./pages/Electricity/Portfolio' /* webpackChunkName: "electricity" */))
const ElectricityDistribution = lazy(() => import('./pages/Electricity/Distribution' /* webpackChunkName: "electricity" */)) // prettier-ignore
const ElectricityInvoicing = lazy(() => import('./pages/Electricity/Invoicing' /* webpackChunkName: "electricity" */))
const ElectricityOkte = lazy(() => import('./pages/Electricity/Okte/Okte' /* webpackChunkName: "electricity" */))
const ElectricityTrading = lazy(() => import('./pages/Electricity/Trading' /* webpackChunkName: "electricity" */))

const ClientHome = lazy(() => import('./pages/ClientHome' /* webpackChunkName: "clienthome" */))
const ClientSettings = lazy(() => import('./pages/ClientSettings' /* webpackChunkName: "clientsettings" */))
const ClientElectricity = lazy(() => import('./pages/ClientHome/ClientElectricity' /* webpackChunkName: "clientelectricity" */)) // prettier-ignore
const ClientInvoicing = lazy(() => import('./pages/ClientHome/ClientInvoicing' /* webpackChunkName: "clientinvoicing" */)) // prettier-ignore

const TraderHome = lazy(() => import('./pages/TraderHome/TraderHome' /* webpackChunkName: "traderhome" */))
const TraderSettings = lazy(() => import('./pages/TraderSettings' /* webpackChunkName: "tradersettings" */))
const TraderElectricity = lazy(() => import('./pages/TraderHome/TraderElectricity' /* webpackChunkName: "traderelectricity" */)) // prettier-ignore
const TraderElectricityPortfolio = lazy(() => import('./pages/TraderHome/TraderElectricity/TraderPortfolio' /* webpackChunkName: "traderelectricity" */)) // prettier-ignore
const TraderElectricityInvoicing = lazy(() => import('./pages/TraderHome/TraderElectricity/TraderInvoicing' /* webpackChunkName: "traderelectricity" */)) // prettier-ignore
const TraderElectricityOkte = lazy(() => import('./pages/TraderHome/TraderElectricity/TraderOkte' /* webpackChunkName: "traderelectricity" */)) // prettier-ignore
const TraderTrade = lazy(() => import('./pages/TraderHome/TraderTrade' /* webpackChunkName: "tradertrade" */)) // prettier-ignore
const TraderTradeMyTrade = lazy(() => import('./pages/TraderHome/TraderTrade/MyTrade/TraderMyTrade' /* webpackChunkName: "tradertrade" */)) // prettier-ignore

// eslint-disable-next-line react/display-name
const RoleGuardedRoute = ({ role }: { role: UserRole }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(user$)

  const [loading, setLoading] = useState(!user?.role)

  useEffect(() => {
    if (hasToken() && !user) {
      setLoading(true)
      dispatch(getMe())
        .then((res: any) => {
          if (res.data) {
            setLoading(false)
            return res
          }
          removeToken()
          setLoading(false)
          navigate('/login')
          return res
        })
        .catch(() => {
          removeToken()
          setLoading(false)
          navigate('/login')
        })
    }
  }, [dispatch, user])

  useEffect(() => {
    if (!hasToken() && !user) {
      navigate('/login')
    }
  }, [])

  if (loading) {
    return <BaseLayout loading defaultTitle='Môj Encare' customPageLoader={<Loader />} />
  }

  let homePath = '/'
  if (user?.role === UserRole.CLIENT) homePath = '/client'
  if (user?.role === UserRole.TRADER) homePath = '/trader'

  return user?.role === role ? <Outlet /> : <Navigate to={homePath} />
}

const AppRouter = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense
          fallback={
            <Delay ms={250}>
              <BasePageLoader />
            </Delay>
          }
        >
          <Routes>
            <Route path='login' element={<LayoutRoute component={Login} isAnonym />} />
            <Route path='forgotten-password' element={<LayoutRoute component={ForgottenPassword} isAnonym />} />
            <Route path='reset-password' element={<LayoutRoute component={ResetPassword} isAnonym />} />
            <Route path='logout' element={<LogoutPage />} />

            <Route path='/' element={<RoleGuardedRoute role={UserRole.ADMIN} />}>
              <Route path='' index element={<LayoutRoute component={Home} />} />
              <Route path='home' element={<LayoutRoute component={Home} />} />
              <Route path='tasks' element={<LayoutRoute component={TasksPage} />} />
              <Route path='notifications' element={<LayoutRoute component={NotificationPage} />} />
              <Route path='approvals' element={<LayoutRoute component={ApprovalsPage} />} />
              <Route path='warnings' element={<LayoutRoute component={WarningPage} />} />
              <Route path='settings/' element={<LayoutRoute component={Settings} />} />
              <Route path='settings/users/:id' element={<LayoutRoute component={UserDetailsPage} />} />

              <Route path='administration'>
                <Route path='' index element={<LayoutRoute component={Administration} />} />
                <Route path='partners/*' element={<LayoutRoute component={Partners} />} />
                <Route path='backoffice/*' element={<LayoutRoute component={BackOffice} />} />
                <Route path='trade/*' element={<LayoutRoute component={AdministrationTrade} />} />
                <Route path='mediation/*' element={<LayoutRoute component={Mediation} />} />
              </Route>
              <Route path='gas'>
                <Route path='' index element={<LayoutRoute component={Gas} />} />
                <Route path='portfolio/*' element={<LayoutRoute component={GasPortfolio} />} />
                <Route path='distribution/*' element={<LayoutRoute component={GasDistribution} />} />
                <Route path='invoicing/*' element={<LayoutRoute component={GasInvoicing} />} />
                <Route path='meter-values/*' element={<LayoutRoute component={GasMeterValues} />} />
                <Route path='trading/*' element={<LayoutRoute component={GasTrading} />} />
              </Route>
              <Route path='financials'>
                <Route path='' index element={<LayoutRoute component={Financials} />} />
                <Route path='financial/*' element={<LayoutRoute component={FinancialsFinancial} />} />
                <Route path='financial_reporting/*' element={<LayoutRoute component={FinancialReporting} />} />
                <Route path='other/*' element={<LayoutRoute component={FinancialOther} />} />
              </Route>
              <Route path='trade'>
                <Route path='' index element={<LayoutRoute component={Trade} />} />
                <Route path='my-trade/drafts/*' element={<LayoutRoute component={TradeDrafts} />} />
                <Route path='offers/*' element={<LayoutRoute component={TradeOffers} />} />
                <Route path='database/*' element={<LayoutRoute component={TradeDatabase} />} />
                <Route path='reports/*' element={<LayoutRoute component={TradeReports} />} />
              </Route>
              <Route path='electricity'>
                <Route path='' index element={<LayoutRoute component={Electricity} />} />
                <Route path='portfolio/*' element={<LayoutRoute component={ElectricityPortfolio} />} />
                <Route path='distribution/*' element={<LayoutRoute component={ElectricityDistribution} />} />
                <Route path='invoicing/*' element={<LayoutRoute component={ElectricityInvoicing} />} />
                <Route path='okte/*' element={<LayoutRoute component={ElectricityOkte} />} />
                <Route path='trading/*' element={<LayoutRoute component={ElectricityTrading} />} />
              </Route>
            </Route>

            <Route path='client' element={<RoleGuardedRoute role={UserRole.CLIENT} />}>
              <Route path='' index element={<LayoutRoute isClientRoute component={ClientHome} />} />
              <Route path='electricity/*' element={<LayoutRoute isClientRoute component={ClientElectricity} />} />
              <Route path='invoicing/*' element={<LayoutRoute isClientRoute component={ClientInvoicing} />} />
              <Route path='settings' element={<LayoutRoute isClientRoute component={ClientSettings} />} />
            </Route>

            <Route path='trader' element={<RoleGuardedRoute role={UserRole.TRADER} />}>
              <Route path='' index element={<LayoutRoute isTraderRoute component={TraderHome} />} />
              <Route path='electricity'>
                <Route path='' index element={<LayoutRoute isTraderRoute component={TraderElectricity} />} />
                <Route
                  path='portfolio/*'
                  element={<LayoutRoute isTraderRoute component={TraderElectricityPortfolio} />}
                />
                <Route
                  path='invoicing/*'
                  element={<LayoutRoute isTraderRoute component={TraderElectricityInvoicing} />}
                />
                <Route path='okte/*' element={<LayoutRoute isTraderRoute component={TraderElectricityOkte} />} />
              </Route>
              <Route path='trade'>
                <Route path='' index element={<LayoutRoute isTraderRoute component={TraderTrade} />} />
                <Route path='my-trade/*' element={<LayoutRoute isTraderRoute component={TraderTradeMyTrade} />} />
              </Route>
              <Route path='settings' element={<LayoutRoute isTraderRoute component={TraderSettings} />} />
            </Route>

            <Route element={<LayoutRoute component={NotFoundPage} isAnonym />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </Router>
  )
}

AppRouter.displayName = 'AppRouter'
export default AppRouter
