import React, { ReactNode } from 'react'
import { ColorsType, Container, Grid, HorizontalScroll, Typography } from '@otion-core/sandy'

import * as S from './styles'

interface Category {
  to: string
  text: string
  icon: ReactNode
  disabled?: boolean
}

interface CategoryMenuProps {
  data: Category[]
  color: ColorsType
}

const CategoryMenu = ({ data }: CategoryMenuProps) => {
  return (
    <Container fullWidth>
      <HorizontalScroll>
        {data.map(category => (
          <Grid.Item key={category.text}>
            <S.Toolbox
              backgroundColor='white'
              key={category.to}
              to={category.to}
              icon={category.icon}
              text={
                <Typography variant='heading' size='small' weight='medium' inline>
                  {category.text}
                </Typography>
              }
              size='large'
              disabled={category.disabled}
            />
          </Grid.Item>
        ))}
      </HorizontalScroll>
    </Container>
  )
}

CategoryMenu.displayName = 'CategoryMenu'
export default CategoryMenu
