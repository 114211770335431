import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { MessageStatusEnum } from 'src/shared/interfaces'

interface MessageStatusProps {
  value?: number
}

const MessageStatus = (props: MessageStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null
  let color

  switch (props.value) {
    case MessageStatusEnum.SENT:
      color = '#e6e6e6'
      break
    case MessageStatusEnum.DELIVERED:
      color = '#f3d163'
      break
    case MessageStatusEnum.DECLINED:
      color = '#ff3b30'
      break
    case MessageStatusEnum.PAIRED:
      color = '#8abd25'
      break
    case MessageStatusEnum.ERROR:
      color = '#ff3b30'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`MESSAGE_STATUS.${props.value}` as never)}</span>
    </Container>
  )
}

MessageStatus.displayName = 'MessageStatus'
export default MessageStatus
