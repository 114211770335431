import * as InvoicesGasApi from '../../apis/invoicesGas.api'
import { IInvoice, IInvoicesFilter, IPostGenerateInvoices, PromiseAction, TableSort } from '../../shared/interfaces'

// Get paginated invoices
export const GET_ALL_INVOICES_GAS_TRIGGERED = 'GET_ALL_INVOICES_GAS_TRIGGERED'
export const GET_ALL_INVOICES_GAS_SUCCESS = 'GET_ALL_INVOICES_GAS_SUCCESS'
export const GET_ALL_INVOICES_GAS_FAILURE = 'GET_ALL_INVOICES_GAS_FAILURE'

export function getAllInvoicesGas(
  filter: IInvoicesFilter,
  sort: TableSort<IInvoice> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = InvoicesGasApi.getAllInvoicesGas(filter, sort, page, size)
  return {
    onRequest: GET_ALL_INVOICES_GAS_TRIGGERED,
    onSuccess: GET_ALL_INVOICES_GAS_SUCCESS,
    onFailure: GET_ALL_INVOICES_GAS_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get invoice details
export const GET_INVOICE_GAS_DETAILS_TRIGGERED = 'GET_INVOICE_GAS_DETAILS_TRIGGERED'
export const GET_INVOICE_GAS_DETAILS_SUCCESS = 'GET_INVOICE_GAS_DETAILS_SUCCESS'
export const GET_INVOICE_GAS_DETAILS_FAILURE = 'GET_INVOICE_GAS_DETAILS_FAILURE'

export function getInvoiceGasDetails(id: number): PromiseAction {
  const promise = InvoicesGasApi.getInvoiceGasDetails(id)
  return {
    onRequest: GET_INVOICE_GAS_DETAILS_TRIGGERED,
    onSuccess: GET_INVOICE_GAS_DETAILS_SUCCESS,
    onFailure: GET_INVOICE_GAS_DETAILS_FAILURE,
    promise
  }
}

// Update invoice details
export const UPDATE_INVOICE_GAS_DETAILS_TRIGGERED = 'UPDATE_INVOICE_GAS_DETAILS_TRIGGERED'
export const UPDATE_INVOICE_GAS_DETAILS_SUCCESS = 'UPDATE_INVOICE_GAS_DETAILS_SUCCESS'
export const UPDATE_INVOICE_GAS_DETAILS_FAILURE = 'UPDATE_INVOICE_GAS_DETAILS_FAILURE'

export function updateInvoiceGasDetails(id: number, payload: Partial<IInvoice>): PromiseAction {
  const promise = InvoicesGasApi.updateInvoiceGasDetails(id, payload)
  return {
    onRequest: UPDATE_INVOICE_GAS_DETAILS_TRIGGERED,
    onSuccess: UPDATE_INVOICE_GAS_DETAILS_SUCCESS,
    onFailure: UPDATE_INVOICE_GAS_DETAILS_FAILURE,
    promise
  }
}

// Download pdf invoice
export const GET_PDF_INVOICE_GAS_TRIGGERED = 'GET_PDF_INVOICE_GAS_TRIGGERED'
export const GET_PDF_INVOICE_GAS_SUCCESS = 'GET_PDF_INVOICE_GAS_SUCCESS'
export const GET_PDF_INVOICE_GAS_FAILURE = 'GET_PDF_INVOICE_GAS_FAILURE'

export function getPdfInvoiceGas(id: number): PromiseAction {
  const promise = InvoicesGasApi.getPdfInvoiceGas(id)
  return {
    onRequest: GET_PDF_INVOICE_GAS_TRIGGERED,
    onSuccess: GET_PDF_INVOICE_GAS_SUCCESS,
    onFailure: GET_PDF_INVOICE_GAS_FAILURE,
    promise
  }
}

// Preview pdf invoice
export const PREVIEW_PDF_INVOICE_GAS_TRIGGERED = 'PREVIEW_PDF_INVOICE_GAS_TRIGGERED'
export const PREVIEW_PDF_INVOICE_GAS_SUCCESS = 'PREVIEW_PDF_INVOICE_GAS_SUCCESS'
export const PREVIEW_PDF_INVOICE_GAS_FAILURE = 'PREVIEW_PDF_INVOICE_GAS_FAILURE'

export function getPdfInvoiceGasPreview(id: number): PromiseAction {
  const promise = InvoicesGasApi.getPdfInvoiceGasPreview(id)
  return {
    onRequest: PREVIEW_PDF_INVOICE_GAS_TRIGGERED,
    onSuccess: PREVIEW_PDF_INVOICE_GAS_SUCCESS,
    onFailure: PREVIEW_PDF_INVOICE_GAS_FAILURE,
    promise
  }
}

// Generate Proforma Invoices for current month
export const POST_GENERATE_PROFORMA_INVOICES_GAS_TRIGGERED = 'POST_GENERATE_PROFORMA_INVOICES_GAS_TRIGGERED'
export const POST_GENERATE_PROFORMA_INVOICES_GAS_SUCCESS = 'POST_GENERATE_PROFORMA_INVOICES_GAS_SUCCESS'
export const POST_GENERATE_PROFORMA_INVOICES_GAS_FAILURE = 'POST_GENERATE_PROFORMA_INVOICES_GAS_FAILURE'

export function postGenerateProformaInvoicesGas(payload: IPostGenerateInvoices): PromiseAction {
  const promise = InvoicesGasApi.generateProformaInvoicesGas(payload)
  return {
    onRequest: POST_GENERATE_PROFORMA_INVOICES_GAS_TRIGGERED,
    onSuccess: POST_GENERATE_PROFORMA_INVOICES_GAS_SUCCESS,
    onFailure: POST_GENERATE_PROFORMA_INVOICES_GAS_FAILURE,
    promise,
    payload: { payload }
  }
}

// Generate Payment Invoices for current month
export const POST_GENERATE_PAYMENT_INVOICES_GAS_TRIGGERED = 'POST_GENERATE_PAYMENT_INVOICES_GAS_TRIGGERED'
export const POST_GENERATE_PAYMENT_INVOICES_GAS_SUCCESS = 'POST_GENERATE_PAYMENT_INVOICES_GAS_SUCCESS'
export const POST_GENERATE_PAYMENT_INVOICES_GAS_FAILURE = 'POST_GENERATE_PAYMENT_INVOICES_GAS_FAILURE'

export function postGeneratePaymentInvoicesGas(payload: IPostGenerateInvoices): PromiseAction {
  const promise = InvoicesGasApi.generatePaymentInvoicesGas(payload)
  return {
    onRequest: POST_GENERATE_PAYMENT_INVOICES_GAS_TRIGGERED,
    onSuccess: POST_GENERATE_PAYMENT_INVOICES_GAS_SUCCESS,
    onFailure: POST_GENERATE_PAYMENT_INVOICES_GAS_FAILURE,
    promise,
    payload: { payload }
  }
}

// Send selected invoice by email
export const SEND_INVOICE_GAS_TRIGGERED = 'SEND_INVOICE_GAS_TRIGGERED'
export const SEND_INVOICE_GAS_SUCCESS = 'SEND_INVOICE_GAS_SUCCESS'
export const SEND_INVOICE_GAS_FAILURE = 'SEND_INVOICE_GAS_FAILURE'

export function sendInvoiceGas(id: number): PromiseAction {
  const promise = InvoicesGasApi.sendInvoiceGas(id)
  return {
    onRequest: SEND_INVOICE_GAS_TRIGGERED,
    onSuccess: SEND_INVOICE_GAS_SUCCESS,
    onFailure: SEND_INVOICE_GAS_FAILURE,
    promise
  }
}

// Force issue selected draft invoice
export const FORCE_ISSUE_INVOICE_GAS_TRIGGERED = 'FORCE_ISSUE_INVOICE_GAS_TRIGGERED'
export const FORCE_ISSUE_INVOICE_GAS_SUCCESS = 'FORCE_ISSUE_INVOICE_GAS_SUCCESS'
export const FORCE_ISSUE_INVOICE_GAS_FAILURE = 'FORCE_ISSUE_INVOICE_GAS_FAILURE'

export function forceIssueInvoiceGas(id: number): PromiseAction {
  const promise = InvoicesGasApi.forceIssueInvoiceGas(id)
  return {
    onRequest: FORCE_ISSUE_INVOICE_GAS_TRIGGERED,
    onSuccess: FORCE_ISSUE_INVOICE_GAS_SUCCESS,
    onFailure: FORCE_ISSUE_INVOICE_GAS_FAILURE,
    promise
  }
}

// Cancel selected invoice
export const CANCEL_INVOICE_GAS_TRIGGERED = 'CANCEL_INVOICE_GAS_TRIGGERED'
export const CANCEL_INVOICE_GAS_SUCCESS = 'CANCEL_INVOICE_GAS_SUCCESS'
export const CANCEL_INVOICE_GAS_FAILURE = 'CANCEL_INVOICE_GAS_FAILURE'

export function cancelInvoiceGas(id: number): PromiseAction {
  const promise = InvoicesGasApi.cancelInvoiceGas(id)
  return {
    onRequest: CANCEL_INVOICE_GAS_TRIGGERED,
    onSuccess: CANCEL_INVOICE_GAS_SUCCESS,
    onFailure: CANCEL_INVOICE_GAS_FAILURE,
    promise
  }
}

// Reload customer info, regenerate invoice
export const REGENERATE_INVOICE_GAS_TRIGGERED = 'REGENERATE_INVOICE_GAS_TRIGGERED'
export const REGENERATE_INVOICE_GAS_SUCCESS = 'REGENERATE_INVOICE_GAS_SUCCESS'
export const REGENERATE_INVOICE_GAS_FAILURE = 'REGENERATE_INVOICE_GAS_FAILURE'

export function regenerateInvoiceGas(id: number): PromiseAction {
  const promise = InvoicesGasApi.regenerateInvoiceGas(id)
  return {
    onRequest: REGENERATE_INVOICE_GAS_TRIGGERED,
    onSuccess: REGENERATE_INVOICE_GAS_SUCCESS,
    onFailure: REGENERATE_INVOICE_GAS_FAILURE,
    promise
  }
}

// Export invoices list in xls
export const EXPORT_INVOICES_GAS_TRIGGERED = 'EXPORT_INVOICES_GAS_TRIGGERED'
export const EXPORT_INVOICES_GAS_SUCCESS = 'EXPORT_INVOICES_GAS_SUCCESS'
export const EXPORT_INVOICES_GAS_FAILURE = 'EXPORT_INVOICES_GAS_FAILURE'

export function exportInvoicesGas(filter: IInvoicesFilter): PromiseAction {
  const promise = InvoicesGasApi.exportInvoicesGas(filter)
  return {
    onRequest: EXPORT_INVOICES_GAS_TRIGGERED,
    onSuccess: EXPORT_INVOICES_GAS_SUCCESS,
    onFailure: EXPORT_INVOICES_GAS_FAILURE,
    promise
  }
}
