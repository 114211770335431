/* eslint-disable max-lines */
import * as FinancialApi from '../../apis/financial.api'
import {
  IBankAccount,
  IBankAccountFilter,
  IBill,
  IBillsFilter,
  IExpenseCreatePayload,
  IExpenseUploadFile,
  IIncomeCreatePayload,
  IMoveBalancePayload,
  IPairManuallyPayload,
  IUpdateDtoBankAccount,
  IUpdateDtoBill,
  PromiseAction,
  TableSort
} from '../../shared/interfaces'

// Get paginated bank account
export const GET_BANK_ACCOUNT_TRIGGERED = 'GET_BANK_ACCOUNT_TRIGGERED'
export const GET_BANK_ACCOUNT_SUCCESS = 'GET_BANK_ACCOUNT_SUCCESS'
export const GET_BANK_ACCOUNT_FAILURE = 'GET_BANK_ACCOUNT_FAILURE'

export function getBankAccount(
  filter: IBankAccountFilter,
  sort: TableSort<IBankAccount> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = FinancialApi.getAllBankAccount(filter, sort, page, size)
  return {
    onRequest: GET_BANK_ACCOUNT_TRIGGERED,
    onSuccess: GET_BANK_ACCOUNT_SUCCESS,
    onFailure: GET_BANK_ACCOUNT_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get bank account details
export const GET_BANK_ACCOUNT_DETAIL_TRIGGERED = 'GET_BANK_ACCOUNT_DETAIL_TRIGGERED'
export const GET_BANK_ACCOUNT_DETAIL_SUCCESS = 'GET_BANK_ACCOUNT_DETAIL_SUCCESS'
export const GET_BANK_ACCOUNT_DETAIL_FAILURE = 'GET_BANK_ACCOUNT_DETAIL_FAILURE'

export function getBankAccountDetail(id: number): PromiseAction {
  const promise = FinancialApi.getBankAccountDetail(id)
  return {
    onRequest: GET_BANK_ACCOUNT_DETAIL_TRIGGERED,
    onSuccess: GET_BANK_ACCOUNT_DETAIL_SUCCESS,
    onFailure: GET_BANK_ACCOUNT_DETAIL_FAILURE,
    promise,
    payload: { id }
  }
}

// Get bank account statuses
export const GET_BANK_ACCOUNT_STATUSES_TRIGGERED = 'GET_BANK_ACCOUNT_STATUSES_TRIGGERED'
export const GET_BANK_ACCOUNT_STATUSES_SUCCESS = 'GET_BANK_ACCOUNT_STATUSES_SUCCESS'
export const GET_BANK_ACCOUNT_STATUSES_FAILURE = 'GET_BANK_ACCOUNT_STATUSES_FAILURE'

export function getBankAccountStatuses(): PromiseAction {
  const promise = FinancialApi.getBankAccountStatuses()
  return {
    onRequest: GET_BANK_ACCOUNT_STATUSES_TRIGGERED,
    onSuccess: GET_BANK_ACCOUNT_STATUSES_SUCCESS,
    onFailure: GET_BANK_ACCOUNT_STATUSES_FAILURE,
    promise
  }
}

// Get paginated income bills
export const GET_INCOMES_TRIGGERED = 'GET_INCOMES_TRIGGERED'
export const GET_INCOMES_SUCCESS = 'GET_INCOMES_SUCCESS'
export const GET_INCOMES_FAILURE = 'GET_INCOMES_FAILURE'

export function getIncomes(
  filter: IBillsFilter,
  sort: TableSort<IBill> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = FinancialApi.getAllIncomes(filter, sort, page, size)
  return {
    onRequest: GET_INCOMES_TRIGGERED,
    onSuccess: GET_INCOMES_SUCCESS,
    onFailure: GET_INCOMES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get paginated expense bills
export const GET_EXPENSES_TRIGGERED = 'GET_EXPENSES_TRIGGERED'
export const GET_EXPENSES_SUCCESS = 'GET_EXPENSES_SUCCESS'
export const GET_EXPENSES_FAILURE = 'GET_EXPENSES_FAILURE'

export function getExpenses(
  filter: IBillsFilter,
  sort: TableSort<IBill> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = FinancialApi.getAllExpenses(filter, sort, page, size)
  return {
    onRequest: GET_EXPENSES_TRIGGERED,
    onSuccess: GET_EXPENSES_SUCCESS,
    onFailure: GET_EXPENSES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get bill details
export const GET_BILL_DETAIL_TRIGGERED = 'GET_BILL_DETAIL_TRIGGERED'
export const GET_BILL_DETAIL_SUCCESS = 'GET_BILL_DETAIL_SUCCESS'
export const GET_BILL_DETAIL_FAILURE = 'GET_BILL_DETAIL_FAILURE'

export function getBillDetail(id: number): PromiseAction {
  const promise = FinancialApi.getBillDetail(id)
  return {
    onRequest: GET_BILL_DETAIL_TRIGGERED,
    onSuccess: GET_BILL_DETAIL_SUCCESS,
    onFailure: GET_BILL_DETAIL_FAILURE,
    promise,
    payload: { id }
  }
}

// Get all bills history by bill
export const GET_BILL_HISTORY_BY_BILL_TRIGGERED = 'GET_BILL_HISTORY_BY_BILL_TRIGGERED'
export const GET_BILL_HISTORY_BY_BILL_SUCCESS = 'GET_BILL_HISTORY_BY_BILL_SUCCESS'
export const GET_BILL_HISTORY_BY_BILL_FAILURE = 'GET_BILL_HISTORY_BY_BILL_FAILURE'

export function getBillHistoryByBill(billId: number): PromiseAction {
  const promise = FinancialApi.getBillHistoryByBill(billId)
  return {
    onRequest: GET_BILL_HISTORY_BY_BILL_TRIGGERED,
    onSuccess: GET_BILL_HISTORY_BY_BILL_SUCCESS,
    onFailure: GET_BILL_HISTORY_BY_BILL_FAILURE,
    promise,
    payload: { billId }
  }
}

// Get all bills history by bank account
export const GET_BILL_HISTORY_BY_BANK_ACCOUNT_TRIGGERED = 'GET_BILL_HISTORY_BY_BANK_ACCOUNT_TRIGGERED'
export const GET_BILL_HISTORY_BY_BANK_ACCOUNT_SUCCESS = 'GET_BILL_HISTORY_BY_BANK_ACCOUNT_SUCCESS'
export const GET_BILL_HISTORY_BY_BANK_ACCOUNT_FAILURE = 'GET_BILL_HISTORY_BY_BANK_ACCOUNT_FAILURE'

export function getBillHistoryByBankAccount(bankAccountId: number): PromiseAction {
  const promise = FinancialApi.getBillHistoryByBankAccount(bankAccountId)
  return {
    onRequest: GET_BILL_HISTORY_BY_BANK_ACCOUNT_TRIGGERED,
    onSuccess: GET_BILL_HISTORY_BY_BANK_ACCOUNT_SUCCESS,
    onFailure: GET_BILL_HISTORY_BY_BANK_ACCOUNT_FAILURE,
    promise,
    payload: { bankAccountId }
  }
}

// Get unpaired bank accounts suggestions for the bill
export const GET_BANK_ACCOUNT_SUGGESTIONS_TRIGGERED = 'GET_BANK_ACCOUNT_SUGGESTIONS_TRIGGERED'
export const GET_BANK_ACCOUNT_SUGGESTIONS_SUCCESS = 'GET_BANK_ACCOUNT_SUGGESTIONS_SUCCESS'
export const GET_BANK_ACCOUNT_SUGGESTIONS_FAILURE = 'GET_BANK_ACCOUNT_SUGGESTIONS_FAILURE'

export function getBankAccountSuggestions(id: number): PromiseAction {
  const promise = FinancialApi.getBankAccountSuggestions(id)
  return {
    onRequest: GET_BANK_ACCOUNT_SUGGESTIONS_TRIGGERED,
    onSuccess: GET_BANK_ACCOUNT_SUGGESTIONS_SUCCESS,
    onFailure: GET_BANK_ACCOUNT_SUGGESTIONS_FAILURE,
    promise,
    payload: { id }
  }
}

// Manually pair the bank account to the bill
export const POST_PAIR_MANUALLY_TRIGGERED = 'POST_PAIR_MANUALLY_TRIGGERED'
export const POST_PAIR_MANUALLY_SUCCESS = 'POST_PAIR_MANUALLY_SUCCESS'
export const POST_PAIR_MANUALLY_FAILURE = 'POST_PAIR_MANUALLY_FAILURE'

export function postPairManually(payload: IPairManuallyPayload): PromiseAction {
  const promise = FinancialApi.postPairManually(payload)
  return {
    onRequest: POST_PAIR_MANUALLY_TRIGGERED,
    onSuccess: POST_PAIR_MANUALLY_SUCCESS,
    onFailure: POST_PAIR_MANUALLY_FAILURE,
    promise,
    payload
  }
}

// Move balance from one bill to another
export const POST_TAKE_BALANCE_TRIGGERED = 'POST_TAKE_BALANCE_TRIGGERED'
export const POST_TAKE_BALANCE_SUCCESS = 'POST_TAKE_BALANCE_SUCCESS'
export const POST_TAKE_BALANCE_FAILURE = 'POST_TAKE_BALANCE_FAILURE'

export function postTakeBalance(payload: IMoveBalancePayload): PromiseAction {
  const promise = FinancialApi.postMoveBalance(payload)
  return {
    onRequest: POST_TAKE_BALANCE_TRIGGERED,
    onSuccess: POST_TAKE_BALANCE_SUCCESS,
    onFailure: POST_TAKE_BALANCE_FAILURE,
    promise,
    payload
  }
}

// Account proforma bill into selected bill
export const POST_ACCOUNT_PROFORMA_BILL_TRIGGERED = 'POST_ACCOUNT_PROFORMA_BILL_TRIGGERED'
export const POST_ACCOUNT_PROFORMA_BILL_SUCCESS = 'POST_ACCOUNT_PROFORMA_BILL_SUCCESS'
export const POST_ACCOUNT_PROFORMA_BILL_FAILURE = 'POST_ACCOUNT_PROFORMA_BILL_FAILURE'

export function postAccountProformaBill(payload: IMoveBalancePayload): PromiseAction {
  const promise = FinancialApi.postAccountProformaBill(payload)
  return {
    onRequest: POST_ACCOUNT_PROFORMA_BILL_TRIGGERED,
    onSuccess: POST_ACCOUNT_PROFORMA_BILL_SUCCESS,
    onFailure: POST_ACCOUNT_PROFORMA_BILL_FAILURE,
    promise,
    payload
  }
}

// create new Income Bill
export const CREATE_INCOME_BILL_TRIGGERED = 'CREATE_INCOME_BILL_TRIGGERED'
export const CREATE_INCOME_BILL_SUCCESS = 'CREATE_INCOME_BILL_SUCCESS'
export const CREATE_INCOME_BILL_FAILURE = 'CREATE_INCOME_BILL_FAILURE'

export function createIncomeBill(payload: IIncomeCreatePayload): PromiseAction {
  const promise = FinancialApi.createIncomeBill(payload)
  return {
    onRequest: CREATE_EXPENSE_BILL_TRIGGERED,
    onSuccess: CREATE_EXPENSE_BILL_SUCCESS,
    onFailure: CREATE_EXPENSE_BILL_FAILURE,
    promise
  }
}

// create new Expense Bill
export const CREATE_EXPENSE_BILL_TRIGGERED = 'CREATE_EXPENSE_BILL_TRIGGERED'
export const CREATE_EXPENSE_BILL_SUCCESS = 'CREATE_EXPENSE_BILL_SUCCESS'
export const CREATE_EXPENSE_BILL_FAILURE = 'CREATE_EXPENSE_BILL_FAILURE'

export function createExpenseBill(payload: IExpenseCreatePayload): PromiseAction {
  const promise = FinancialApi.createExpenseBill(payload)
  return {
    onRequest: CREATE_EXPENSE_BILL_TRIGGERED,
    onSuccess: CREATE_EXPENSE_BILL_SUCCESS,
    onFailure: CREATE_EXPENSE_BILL_FAILURE,
    promise
  }
}

// remove file from the bill
export const REMOVE_BILL_FILE_TRIGGERED = 'REMOVE_BILL_FILE_TRIGGERED'
export const REMOVE_BILL_FILE_SUCCESS = 'REMOVE_BILL_FILE_SUCCESS'
export const REMOVE_BILL_FILE_FAILURE = 'REMOVE_BILL_FILE_FAILURE'

export function removeFileFromBill(billId: number, fileId: number): PromiseAction {
  const promise = FinancialApi.removeFileFromBill(billId, fileId)
  return {
    onRequest: REMOVE_BILL_FILE_TRIGGERED,
    onSuccess: REMOVE_BILL_FILE_SUCCESS,
    onFailure: REMOVE_BILL_FILE_FAILURE,
    promise
  }
}

// upload files to bill
export const UPLOAD_FILES_TO_BILL_TRIGGERED = 'UPLOAD_FILES_TO_BILL_TRIGGERED'
export const UPLOAD_FILES_TO_BILL_SUCCESS = 'UPLOAD_FILES_TO_BILL_SUCCESS'
export const UPLOAD_FILES_TO_BILL_FAILURE = 'UPLOAD_FILES_TO_BILL_FAILURE'

export function uploadFilesToBill(billId: number, payload: IExpenseUploadFile): PromiseAction {
  const promise = FinancialApi.uploadFilesToBill(billId, payload)
  return {
    onRequest: UPLOAD_FILES_TO_BILL_TRIGGERED,
    onSuccess: UPLOAD_FILES_TO_BILL_SUCCESS,
    onFailure: UPLOAD_FILES_TO_BILL_FAILURE,
    promise
  }
}

// remove bill
export const REMOVE_BILL_TRIGGERED = 'REMOVE_BILL_TRIGGERED'
export const REMOVE_BILL_SUCCESS = 'REMOVE_BILL_SUCCESS'
export const REMOVE_BILL_FAILURE = 'REMOVE_BILL_FAILURE'

export function removeBillById(id: number): PromiseAction {
  const promise = FinancialApi.removeBill(id)
  return {
    onRequest: REMOVE_BILL_TRIGGERED,
    onSuccess: REMOVE_BILL_SUCCESS,
    onFailure: REMOVE_BILL_FAILURE,
    promise
  }
}

// Update invoice details
export const UPDATE_BILL_DETAILS_TRIGGERED = 'UPDATE_BILL_DETAILS_TRIGGERED'
export const UPDATE_BILL_DETAILS_SUCCESS = 'UPDATE_BILL_DETAILS_SUCCESS'
export const UPDATE_BILL_DETAILS_FAILURE = 'UPDATE_BILL_DETAILS_FAILURE'

export function updateBillDetails(id: number, payload: Partial<IUpdateDtoBill>): PromiseAction {
  const promise = FinancialApi.updateBillDetails(id, payload)
  return {
    onRequest: UPDATE_BILL_DETAILS_TRIGGERED,
    onSuccess: UPDATE_BILL_DETAILS_SUCCESS,
    onFailure: UPDATE_BILL_DETAILS_FAILURE,
    promise
  }
}

// remove bill
export const REMOVE_BILL_HISTORY_ITEM_TRIGGERED = 'REMOVE_BILL_HISTORY_ITEM_TRIGGERED'
export const REMOVE_BILL_BILL_HISTORY_ITEM_SUCCESS = 'REMOVE_BILL_BILL_HISTORY_ITEM_SUCCESS'
export const REMOVE_BILL_BILL_HISTORY_ITEM_FAILURE = 'REMOVE_BILL_BILL_HISTORY_ITEM_FAILURE'

export function removeBillHistoryItemById(id: number): PromiseAction {
  const promise = FinancialApi.removeBillHistoryItem(id)
  return {
    onRequest: REMOVE_BILL_HISTORY_ITEM_TRIGGERED,
    onSuccess: REMOVE_BILL_BILL_HISTORY_ITEM_SUCCESS,
    onFailure: REMOVE_BILL_BILL_HISTORY_ITEM_FAILURE,
    promise
  }
}

// Update bank account
export const UPDATE_BANK_ACCOUNT_TRIGGERED = 'UPDATE_BANK_ACCOUNT_TRIGGERED'
export const UPDATE_BANK_ACCOUNT_SUCCESS = 'UPDATE_BANK_ACCOUNT_SUCCESS'
export const UPDATE_BANK_ACCOUNT_FAILURE = 'UPDATE_BANK_ACCOUNT_FAILURE'

export function updateBankAccount(id: number, payload: Partial<IUpdateDtoBankAccount>): PromiseAction {
  const promise = FinancialApi.updateBankAccount(id, payload)
  return {
    onRequest: UPDATE_BANK_ACCOUNT_TRIGGERED,
    onSuccess: UPDATE_BANK_ACCOUNT_SUCCESS,
    onFailure: UPDATE_BANK_ACCOUNT_FAILURE,
    promise
  }
}

// recalculate bill balance
export const RECALCULATE_BILL_BALANCE_TRIGGERED = 'RECALCULATE_BILL_BALANCE_TRIGGERED'
export const RECALCULATE_BILL_BALANCE_SUCCESS = 'RECALCULATE_BILL_BALANCE_SUCCESS'
export const RECALCULATE_BILL_BALANCE_FAILURE = 'RECALCULATE_BILL_BALANCE_FAILURE'

export function recalculateBillBalance(id: number): PromiseAction {
  const promise = FinancialApi.recalculateBillBalance(id)
  return {
    onRequest: RECALCULATE_BILL_BALANCE_TRIGGERED,
    onSuccess: RECALCULATE_BILL_BALANCE_SUCCESS,
    onFailure: RECALCULATE_BILL_BALANCE_FAILURE,
    promise
  }
}

// get company info from Cribis
export const GET_COMPANY_FROM_CRIBIS_TRIGGERED = 'GET_COMPANY_FROM_CRIBIS_TRIGGERED'
export const GET_COMPANY_FROM_CRIBIS_SUCCESS = 'GET_COMPANY_FROM_CRIBIS_SUCCESS'
export const GET_COMPANY_FROM_CRIBIS_FAILURE = 'GET_COMPANY_FROM_CRIBIS_FAILURE'

export function getCompanyInfoFromCribis(ico: string): PromiseAction {
  const promise = FinancialApi.getCompanyInfoFromCribis(ico)
  return {
    onRequest: GET_COMPANY_FROM_CRIBIS_TRIGGERED,
    onSuccess: GET_COMPANY_FROM_CRIBIS_SUCCESS,
    onFailure: GET_COMPANY_FROM_CRIBIS_FAILURE,
    promise
  }
}

// Get bills suggestions for the bill
export const GET_BILL_SUGGESTIONS_TRIGGERED = 'GET_BILL_SUGGESTIONS_TRIGGERED'
export const GET_BILL_SUGGESTIONS_SUCCESS = 'GET_BILL_SUGGESTIONS_SUCCESS'
export const GET_BILL_SUGGESTIONS_FAILURE = 'GET_BILL_SUGGESTIONS_FAILURE'

export function getBillSuggestions(id: number): PromiseAction {
  const promise = FinancialApi.getBillSuggestions(id)
  return {
    onRequest: GET_BILL_SUGGESTIONS_TRIGGERED,
    onSuccess: GET_BILL_SUGGESTIONS_SUCCESS,
    onFailure: GET_BILL_SUGGESTIONS_FAILURE,
    promise,
    payload: { id }
  }
}

// Get bills suggestions proformas for the bill
export const GET_BILL_SUGGESTIONS_PROFORMAS_TRIGGERED = 'GET_BILL_SUGGESTIONS_PROFORMAS_TRIGGERED'
export const GET_BILL_SUGGESTIONS_PROFORMAS_SUCCESS = 'GET_BILL_SUGGESTIONS_PROFORMAS_SUCCESS'
export const GET_BILL_SUGGESTIONS_PROFORMAS_FAILURE = 'GET_BILL_SUGGESTIONS_PROFORMAS_FAILURE'

export function getBillSuggestionsProformas(id: number): PromiseAction {
  const promise = FinancialApi.getBillSuggestionsProformas(id)
  return {
    onRequest: GET_BILL_SUGGESTIONS_PROFORMAS_TRIGGERED,
    onSuccess: GET_BILL_SUGGESTIONS_PROFORMAS_SUCCESS,
    onFailure: GET_BILL_SUGGESTIONS_PROFORMAS_FAILURE,
    promise,
    payload: { id }
  }
}

// Export bank accounts list in xls
export const EXPORT_BANK_ACCOUNTS_TRIGGERED = 'EXPORT_BANK_ACCOUNTS_TRIGGERED'
export const EXPORT_BANK_ACCOUNTS_SUCCESS = 'EXPORT_BANK_ACCOUNTS_SUCCESS'
export const EXPORT_BANK_ACCOUNTS_FAILURE = 'EXPORT_BANK_ACCOUNTS_FAILURE'

export function exportBankAccounts(filter: IBankAccountFilter): PromiseAction {
  const promise = FinancialApi.exportBankAccounts(filter)
  return {
    onRequest: EXPORT_BANK_ACCOUNTS_TRIGGERED,
    onSuccess: EXPORT_BANK_ACCOUNTS_SUCCESS,
    onFailure: EXPORT_BANK_ACCOUNTS_FAILURE,
    promise
  }
}
// Export expenses list in xls
export const EXPORT_EXPENSES_TRIGGERED = 'EXPORT_EXPENSES_TRIGGERED'
export const EXPORT_EXPENSES_SUCCESS = 'EXPORT_EXPENSES_SUCCESS'
export const EXPORT_EXPENSES_FAILURE = 'EXPORT_EXPENSES_FAILURE'

export function exportExpenses(filter: IBillsFilter): PromiseAction {
  const promise = FinancialApi.exportExpenses(filter)
  return {
    onRequest: EXPORT_EXPENSES_TRIGGERED,
    onSuccess: EXPORT_EXPENSES_SUCCESS,
    onFailure: EXPORT_EXPENSES_FAILURE,
    promise
  }
}
// Export incomes list in xls
export const EXPORT_INCOMES_TRIGGERED = 'EXPORT_INCOMES_TRIGGERED'
export const EXPORT_INCOMES_SUCCESS = 'EXPORT_INCOMES_SUCCESS'
export const EXPORT_INCOMES_FAILURE = 'EXPORT_INCOMES_FAILURE'

export function exportIncomes(filter: IBillsFilter): PromiseAction {
  const promise = FinancialApi.exportIncomes(filter)
  return {
    onRequest: EXPORT_INCOMES_TRIGGERED,
    onSuccess: EXPORT_INCOMES_SUCCESS,
    onFailure: EXPORT_INCOMES_FAILURE,
    promise
  }
}

// Export incomes list in xls
export const EXPORT_UNPAID_INCOMES_TRIGGERED = 'EXPORT_UNPAID_INCOMES_TRIGGERED'
export const EXPORT_UNPAID_INCOMES_SUCCESS = 'EXPORT_UNPAID_INCOMES_SUCCESS'
export const EXPORT_UNPAID_INCOMES_FAILURE = 'EXPORT_UNPAID_INCOMES_FAILURE'

export function exportUnpaidIncomes(): PromiseAction {
  const promise = FinancialApi.exportUnpaidIncomes()
  return {
    onRequest: EXPORT_UNPAID_INCOMES_TRIGGERED,
    onSuccess: EXPORT_UNPAID_INCOMES_SUCCESS,
    onFailure: EXPORT_UNPAID_INCOMES_FAILURE,
    promise
  }
}
