import { useState } from 'react'

import { TableSort } from '../interfaces'

interface UseQuickFilterProps {
  applyFilter: (payload: any, customSort?: TableSort<any>) => void
}

function useQuickFilters<FilterValues = any>({ applyFilter }: UseQuickFilterProps) {
  const [selectedFilterOption, setSelectedFilterOption] = useState<'all' | string>('all')

  const onFilterOptionChange = (
    filterName: string,
    filterValues?: FilterValues,
    resetForm?: () => void,
    sort?: TableSort<any>
  ) => {
    setSelectedFilterOption(filterName)
    if (filterName === 'all') applyFilter({} as FilterValues)
    else applyFilter(filterValues as FilterValues, sort)
    resetForm?.()
  }

  return { selectedFilterOption, onFilterOptionChange }
}

export default useQuickFilters
