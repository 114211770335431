/* eslint-disable max-lines-per-function */
import React, { useState } from 'react'
import { Button, ColorsType, Container, Grid, Input, Modal, Select, Typography } from '@otion-core/sandy'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik, FormikProps } from 'formik'
import moment from 'moment'

import { Option } from 'src/shared/interfaces'
import { DATE_FORMAT_DB } from 'src/shared/constants'
import { ButtonWhite, PopupDatePicker, Tooltip } from 'src/components'
import * as S from 'src/components/commonStyled'
import { IconButton } from 'src/components/commonStyled'
import { useDispatch } from 'react-redux'
import { DownloadIcon } from 'src/assets/icons'

interface TabConfigField<T> {
  name: keyof T
  label?: string
  type: 'text' | 'date' | 'select'
  options?: Option[]
  searchable?: boolean
}

interface QuickFilter<T> {
  name: string
  label: string
  values: T
}

export interface TabConfig<T> {
  name: string
  label: string
  fields: TabConfigField<T>[]
  quickFilters?: QuickFilter<T>[]
}

interface FilterPaneModalProps<FilterValues> {
  color: ColorsType
  onClose: () => void
  onApply: (values: FilterValues) => void
  filter: FilterValues
  modalFilterTabs?: TabConfig<FilterValues>[]
  exportAction?: (filter: any) => any
}

function FilterPaneModal<FilterValues>({
  color,
  onClose,
  onApply,
  filter,
  modalFilterTabs,
  exportAction
}: FilterPaneModalProps<FilterValues>) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [tab, setTab] = useState<string | undefined>(modalFilterTabs?.[0]?.name)
  const [generatingXls, setGeneratingXls] = useState(false)

  const applyQuickFilter = (setFieldValue: (field: string, value: any) => void, quickFilterValues: any) => {
    const appliedValues = { ...filter }
    Object.keys(quickFilterValues)?.forEach(field => {
      setFieldValue(field, quickFilterValues[field])
      appliedValues[field as keyof FilterValues] = quickFilterValues[field]
    })
    onApply(appliedValues)
  }

  const resetFilters = () => {
    onApply({} as FilterValues)
    onClose()
  }

  const onSubmitHandler = (type: 'APPLY' | 'SHOW_RESULTS' | 'EXPORT') => async (values: any) => {
    let appliedValues = {} as FilterValues
    modalFilterTabs?.forEach(tabConfig => {
      if (tab === tabConfig.name) {
        appliedValues = { ...filter }
        tabConfig.fields.forEach(field => {
          appliedValues[field.name] = values[field.name]
        })
      }
    })
    if (type === 'EXPORT') {
      setGeneratingXls(true)
      if (exportAction) {
        await dispatch(exportAction(appliedValues))
      }
      setGeneratingXls(false)
      return
    }
    onApply(appliedValues)
    if (type === 'SHOW_RESULTS') onClose()
  }

  const initialValues = {} as any
  modalFilterTabs?.forEach(tabConfig =>
    tabConfig.fields.forEach(field => {
      let initialValue = filter[field.name] || ''
      if (field.type === 'select') initialValue = (filter[field.name] || '').toString()
      if (field.type === 'date') {
        initialValue = filter[field.name] ? moment(filter[field.name] as string).format(DATE_FORMAT_DB) || '' : ''
      }
      initialValues[field.name] = initialValue
    })
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler('SHOW_RESULTS')}>
      {({ resetForm, values, setFieldValue }: FormikProps<any>) => (
        <Modal
          title={
            <Typography variant='heading' weight='semibold'>
              {t('FILTER.TITLE')}
            </Typography>
          }
          open
          size='medium'
          closeHandler={onClose}
          disableBackdropClick
        >
          <Form>
            <Typography variant='heading' weight='semibold'>
              {t('FILTER.FILTER_BY')}
            </Typography>

            <Container flex top={1}>
              <S.FilterLeftContainer flex direction='column' alignItems='flex-start'>
                {modalFilterTabs?.map(tabConfig => (
                  <Container fullWidth key={tabConfig.name}>
                    {tabConfig.fields?.some(field => Boolean(filter[field.name])) ? (
                      <S.ColorDot color='red' size={12} style={{ position: 'absolute', zIndex: 1 }} />
                    ) : null}
                    <ButtonWhite
                      color={color}
                      active={tab === tabConfig.name}
                      onClick={() => setTab(tabConfig.name)}
                      bottom='small'
                    >
                      {tabConfig.label}
                    </ButtonWhite>
                  </Container>
                ))}
              </S.FilterLeftContainer>

              <S.FilterRightContainer>
                {modalFilterTabs?.map(tabConfig => {
                  if (tab === tabConfig.name) {
                    return (
                      <React.Fragment key={tabConfig.name}>
                        {tabConfig.fields?.map(field => (
                          <React.Fragment key={field.name as string}>
                            {field.label ? (
                              <Typography weight='semibold' size='medium' style={{ marginBottom: '10px' }}>
                                {field.label}
                              </Typography>
                            ) : null}

                            {field.type === 'text' ? (
                              <Field name={field.name} as={Input} placeholder={field.label} />
                            ) : null}

                            {field.type === 'date' ? (
                              <Field name={field.name} component={PopupDatePicker} inputProps={{ id: field.name }} />
                            ) : null}

                            {field.type === 'select' ? (
                              <Field
                                name={field.name}
                                as={Select}
                                placeholder={field.label}
                                options={field.options}
                                enableReset
                                searchable={field.searchable}
                              />
                            ) : null}
                          </React.Fragment>
                        ))}

                        {tabConfig.quickFilters?.length ? (
                          <Container top='medium'>
                            <Typography weight='semibold' size='medium' style={{ marginBottom: '10px' }}>
                              {t('FILTER.OR')}
                            </Typography>
                            <Container flex>
                              {tabConfig.quickFilters.map(quickFilter => (
                                <ButtonWhite
                                  key={quickFilter.name}
                                  color={color}
                                  onClick={() => applyQuickFilter(setFieldValue, quickFilter.values)}
                                  fullWidth={false}
                                  right='small'
                                >
                                  {quickFilter.label}
                                </ButtonWhite>
                              ))}
                            </Container>
                          </Container>
                        ) : null}
                      </React.Fragment>
                    )
                  }
                  return null
                })}
              </S.FilterRightContainer>
            </Container>

            <Container fullWidth top={1}>
              <Grid>
                <Grid.Item medium={3}>
                  <Button
                    size='xsmall'
                    color={`${color}.light`}
                    fullWidth
                    style={{ maxWidth: '160px', fontSize: '13px' }}
                    onClick={() => {
                      resetFilters()
                      resetForm()
                    }}
                  >
                    <Typography size='medium' weight='semibold'>
                      {t('FILTER.RESET')}
                    </Typography>
                  </Button>
                </Grid.Item>
                {exportAction && (
                  <Grid.Item medium={3}>
                    <Tooltip
                      placement='top-start'
                      trigger={
                        <IconButton
                          color={`${color}.light`}
                          disabled={generatingXls}
                          onClick={() => onSubmitHandler('EXPORT')(values)}
                        >
                          <DownloadIcon width={20} />
                        </IconButton>
                      }
                      content={t('FILTER.EXPORT')}
                    />
                  </Grid.Item>
                )}
                <Grid.Item medium={3}>
                  <Button
                    size='xsmall'
                    variant='primary'
                    color={`${color}.light`}
                    fullWidth
                    style={{ fontSize: '13px', maxWidth: 160 }}
                    onClick={() => onSubmitHandler('APPLY')(values)}
                  >
                    <Typography size='medium' weight='semibold'>
                      {t('FILTER.SAVE')}
                    </Typography>
                  </Button>
                </Grid.Item>
                <Grid.Item medium={3}>
                  <Button
                    size='xsmall'
                    variant='primary'
                    color={`${color}.light`}
                    fullWidth
                    style={{ fontSize: '13px', maxWidth: 160 }}
                    type='submit'
                  >
                    <Typography size='medium' weight='semibold'>
                      {t('FILTER.APPLY')}
                    </Typography>
                  </Button>
                </Grid.Item>
              </Grid>
            </Container>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

FilterPaneModal.displayName = 'FilterPaneModal'
export default FilterPaneModal
