import * as MeterValuesApi from '../../apis/meterValues.api'
import { IMeterValues, IMeterValuesFilter, PromiseAction, TableSort } from '../../shared/interfaces'

// Fetch Meter values list
export const GET_METER_VALUES_TRIGGERED = 'GET_METER_VALUES_TRIGGERED'
export const GET_METER_VALUES_SUCCESS = 'GET_METER_VALUES_SUCCESS'
export const GET_METER_VALUES_FAILURE = 'GET_METER_VALUES_FAILURE'

export function getMeterValues(
  filter: IMeterValuesFilter,
  sort: TableSort<IMeterValues> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = MeterValuesApi.getAllMeterValues(filter, sort, page, size)
  return {
    onRequest: GET_METER_VALUES_TRIGGERED,
    onSuccess: GET_METER_VALUES_SUCCESS,
    onFailure: GET_METER_VALUES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Fetch Meter values list
export const GET_METER_VALUES_DETAILS_TRIGGERED = 'GET_METER_VALUES_DETAILS_TRIGGERED'
export const GET_METER_VALUES_DETAILS_SUCCESS = 'GET_METER_VALUES_DETAILS_SUCCESS'
export const GET_METER_VALUES_DETAILS_FAILURE = 'GET_METER_VALUES_DETAILS_FAILURE'

export function getMeterValuesDetails(pod: string, date_from?: string, date_to?: string): PromiseAction {
  const promise = MeterValuesApi.getAllMeterValuesDetails(pod, date_from, date_to)
  return {
    onRequest: GET_METER_VALUES_DETAILS_TRIGGERED,
    onSuccess: GET_METER_VALUES_DETAILS_SUCCESS,
    onFailure: GET_METER_VALUES_DETAILS_FAILURE,
    promise
  }
}
