/* eslint-disable no-shadow */
import { ActionTypesEnum } from './action.interface'

export enum MESSAGE_TYPE {
  SENT = 0,
  INBOX = 1
}

export enum MessageFetchType {
  ALL = 'ALL',
  PAYMENT_INVOICES = 'PAYMENT_INVOICES',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  EXPENSES = 'EXPENSES'
}

export enum MessageStatusEnum {
  SENT = 1,
  DELIVERED,
  DECLINED,
  PAIRED,
  ERROR
}

export interface IMessage {
  id: number
  subject: string
  status: string
  from: string
  to: string
  type: MESSAGE_TYPE
  eic: string
  pds: string
  message_number: string
  message_name: string
  serial_number: string
  case_id: string
  sent_datetime: string
  unique_id: string
  datetime: string
  distribution_process: number
  attachment: string
  file_ids: number[]
}

export interface IMessageFilter {
  search?: string
  type?: MESSAGE_TYPE
  status?: number
  datetime_from?: string
  datetime_to?: string
}

export interface IActionStatus {
  id: number
  name: string
}

export interface IAction {
  id: number
  eic: string
  case_id: string
  customer: string
  caz_id: string
  rdz: string
  subprocess: number
  service: number
  pds: string
  reason_of_decline: string
  status: string
  error_status: string
  action_type: ActionTypesEnum
  connectionId: number
  messages?: IMessage[]
}

export interface IActionFilter {
  search?: string
}

export interface IMailbox {
  mailboxName: string
  subjectPart?: string
  total: number
  parsedCount: number
}

export interface IUploadAttachmentPayload {
  messageId: number
  attachmentBufferBase64: string
}

export interface IMassUploadAttachmentPayload {
  fileName: string
  attachmentBufferBase64: string
}
