import { TableSort, IPagination, IMeterValues, IMeterValuesFilter } from 'src/shared/interfaces'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const getAllMeterValues = (
  filter: IMeterValuesFilter,
  sort: TableSort<IMeterValues> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      'gas_meter_values.date_of_reading': [filter.date_of_reading_from, filter.date_of_reading_to],
      'gas_meter_values.status': filter.status
    },
    searchBy: ['pod.pod', 'gas_meter'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPagination<IMeterValues>>('/gas-meter-values', payload)
}

export const getAllMeterValuesDetails = (pod: string, date_from?: string, date_to?: string) => {
  const payload = {
    date_from,
    date_to
  }

  return Http.get<IPagination<IMeterValues>>(`/gas-meter-values/${pod}`, payload)
}
