import styled from 'styled-components'
import { Toolbox as SToolbox, PolygonalGrid as SPolygonalGrid, getBPHelpers } from '@otion-core/sandy'

const PolygonalGrid = styled(SPolygonalGrid)`
  ${props => getBPHelpers(props.theme.breakpoints).screens('small')} {
    &.square {
      > div {
        margin-top: 0;
        margin-bottom: -1rem;

        > div {
          margin-top: 0 !important;
          margin-bottom: -3.5rem !important;
        }
      }
    }
  }
`

const Toolbox = styled(SToolbox)<{ disabled?: boolean }>`
  margin: auto;
  width: 196px !important;
  height: 196px !important;

  > a {
    > div {
      border-radius: 50%;
      transition: box-shadow 200ms;
    }

    &:hover > div {
      box-shadow: 3px 12px 30px 0 rgb(0 0 0 / 20%);
    }
  }
  ${props =>
    props.disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
  `}
`

export { Toolbox, PolygonalGrid }
