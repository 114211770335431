import { IElectricityVatRates, IGasVatRates } from 'src/shared/interfaces'

import Http from './api'

export const getElectricityVatRates = (date: Date) => {
  return Http.get<IElectricityVatRates[]>(`/vat-rates-electricity/date/${date}`)
}

export const getGasVatRates = (date: Date) => {
  return Http.get<IGasVatRates[]>(`/vat-rates-gas/date/${date}`)
}
